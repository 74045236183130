import { useUserGuideContext } from '@/components/userguide/userGuideContext';
import { OnboardingTask, OnboardingTaskName } from '@/components/userguide/utils';
import { feedbackOpenAtom } from '@/pages/home/feedback/atom';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { StatefulPanel } from 'baseui/accordion';
import { Skeleton } from 'baseui/skeleton';
import { expand } from 'inline-style-expand-shorthand';
import { useAtom } from 'jotai';
import { sumBy } from 'lodash-es';
import { useEffect } from 'react';
import { MdCheck, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

enum BillingTabKeys {
  Overview = 'Overview',
  PaymentMethods = 'PaymentMethods',
  Invoices = 'Invoices',
}

export default function UserGuide() {
  const [css, theme] = useStyletron();

  const { onboardingTasks, isFetching, completeOnboardingTask } = useUserGuideContext();

  return (
    <div
      className={css({
        flex: 1,
        background: theme.colors['background.secondary'],
      })}
    >
      <div>
        <div>
          {isFetching ? (
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: '16px',
              })}
            >
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <Skeleton height="32px" animation width="100%" key={index} />
                ))}
            </div>
          ) : onboardingTasks.length ? (
            <OnboardingTaskList onboardingTasks={onboardingTasks} completeOnboardingTask={completeOnboardingTask} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

type OnboardingTaskListProps = {
  onboardingTasks: OnboardingTask[];
  completeOnboardingTask: (taskName: OnboardingTaskName) => void;
};

function OnboardingTaskList({ onboardingTasks, completeOnboardingTask }: OnboardingTaskListProps) {
  const [css, theme] = useStyletron();

  useEffect(() => {
    completeOnboardingTask(OnboardingTaskName.signup);
  }, [completeOnboardingTask]);

  const tasksToDisplay: OnboardingTask[] = onboardingTasks.map((task) => ({
    ...task,
    completed: task.completed || task.task_name === OnboardingTaskName.signup,
  }));
  const totalCredits = sumBy(tasksToDisplay, 'credits');
  const earnedCredits = sumBy(tasksToDisplay, (task: OnboardingTask) => (task.completed ? task.credits : 0));

  return (
    <>
      <div className={css({ padding: '16px', backgroundColor: theme.colors['background.secondary'] })}>
        <div>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '8px',
            })}
          >
            <div>Onboarding progress</div>
            <div className={css({ fontSize: '14px' })}>{`${earnedCredits}/${totalCredits} Credits`}</div>
          </div>
          <div
            className={css({
              ...expand({ borderRadius: '20px', backgroundColor: theme.colors['background.accent.gray.subtler'] }),
            })}
          >
            <div
              className={css({
                height: '6px',
                background: theme.colors['background.accent.orange.subtle'],
                width: `${(earnedCredits / totalCredits) * 100}%`,
                ...expand({ borderRadius: '20px' }),
              })}
            ></div>
          </div>
        </div>
        {tasksToDisplay.map((task, index) => (
          <OnboardingTaskItem task={task} key={task.task_name} index={index + 1} />
        ))}
      </div>
    </>
  );
}

function OnboardingTaskItem({ task, index }: { task: OnboardingTask; index: number }) {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const [_, setIsFeedbackOpen] = useAtom(feedbackOpenAtom);

  const handleTask = (task: OnboardingTask) => {
    switch (task.task_name) {
      case OnboardingTaskName.createWorkspace:
        navigate('/groups/new');
        break;
      case OnboardingTaskName.loadData:
        navigate('/ingestion');
        break;
      case OnboardingTaskName.addPayment:
        navigate(`/admin/bills?addCreditCard=true&tab=${BillingTabKeys.PaymentMethods}`);
        break;
      case OnboardingTaskName.inviteMember:
        navigate('/admin/users?invite=true');
        break;
      case OnboardingTaskName.giveFeedback:
        setIsFeedbackOpen(true);
        break;
    }
  };

  const buttonTexts: { [index: string]: string } = {
    [OnboardingTaskName.createWorkspace]: 'Create Workspace',
    [OnboardingTaskName.addPayment]: 'Add Payment Method',
    [OnboardingTaskName.inviteMember]: 'Invite Members',
    [OnboardingTaskName.loadData]: 'Load Data',
    [OnboardingTaskName.giveFeedback]: 'Give Feedback',
  };

  let task_name = task.task_name as string;
  if (task_name === 'Sign up for TigerGraph Cloud') {
    task_name = 'Sign up to TGCloud';
  }

  return (
    <StatefulPanel
      title={
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'space-between',
          })}
        >
          <div className={css({ display: 'flex', alignItems: 'center' })}>
            <div
              className={css({
                width: '20px',
                height: '20px',
                color: theme.colors['text.inverse'],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: `${
                  task.completed ? theme.colors['background.accent.orange.bold'] : theme.colors['icon.disabled']
                }`,
                ...expand({ borderRadius: '50%' }),
                flexShrink: 0,
                fontWeight: 400,
              })}
            >
              <div>{task.completed ? <MdCheck size={16} /> : `${index}`}</div>
            </div>
            <div
              className={css({
                marginLeft: '4px',
                fontWeight: 400,
                fontSize: '14px',
                color: theme.colors['text.primary'],
              })}
            >
              {task_name}
            </div>
            {task.task_name === OnboardingTaskName.signup && (
              <div
                className={css({
                  padding: '2px 4px',
                  fontWeight: 400,
                  fontSize: '10px',
                  border: '1px',
                  borderRadius: '1px',
                  color: theme.colors['text.inverse'],
                  backgroundColor: theme.colors['background.accent.gray.bold'],
                  marginLeft: '10px',
                  lineHeight: 'normal',
                  flexShrink: 0,
                })}
              >
                Beta Offer
              </div>
            )}
          </div>
          <div
            className={css({
              justifySelf: 'flex-end',
              background: theme.colors['background.accent.orange.bold'],
              height: '16px',
              display: 'flex',
              alignItems: 'center',
              fontSize: '8px',
              fontWeight: 600,
              color: theme.colors['text.inverse'],
              letterSpacing: '0.24px',
              textTransform: 'uppercase',
              textAlign: 'center',
              marginRight: '2px',
              ...expand({ borderRadius: '40px', padding: '4px 10px' }),
              flexShrink: 0,
            })}
          >{`${task.credits} credits`}</div>
        </div>
      }
      initialState={{
        expanded: false,
      }}
      overrides={{
        Content: {
          style: {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 400,
            color: theme.colors['text.secondary'],
            fontSize: '14px',
            lineHeight: '16px',
            rowGap: '8px',
            ...expand({
              padding: '0 0 20px 30px',
            }),
            backgroundColor: 'inherit',
          },
        },
        Header: {
          style: {
            fontSize: '14px',
            fontWeight: 600,
            fontFamily: 'Roboto',
            ...expand({ padding: '16px 0' }),
            backgroundColor: 'inherit',
          },
        },
        PanelContainer: {
          style: {
            borderBottomColor: theme.colors['border.tertiary'],
          },
        },
        ToggleIcon: ({ $expanded }) =>
          $expanded ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />,
      }}
    >
      {task.description}
      {buttonTexts[task.task_name] && (
        <Button
          onClick={() => handleTask(task)}
          kind="secondary"
          size="compact"
          overrides={{
            BaseButton: {
              style: {
                alignSelf: 'flex-end',
              },
            },
          }}
        >
          {buttonTexts[task.task_name]}
        </Button>
      )}
    </StatefulPanel>
  );
}
