import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';

import { formatBytes, formatCount } from '@/utils/format';
import { DatabaseT, WorkGroupT } from '@/pages/workgroup/type';
import { SettingMenuForDatabase } from '@/pages/workgroup/tab/SettingMenu';
import clsx from 'clsx';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { actionColumnOverrides } from '@/components/table';
import { groupWorkSpacesByDatabase } from '@/pages/workgroup/tab/Graph';
import BranchIcon from './icons/branch.svg?react';
import { parseDate } from '@/lib/date';
import { format } from 'date-fns';
import { Label } from '@tigergraph/app-ui-lib/typography';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { useState } from 'react';
import IconButton from '@/components/IconButton';
import { ChevronDown, ChevronRight, Info } from 'lucide-react';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { StyledToast } from '@tigergraph/app-ui-lib/styledToasterContainer';

type DBRow = DatabaseT & {
  is_rw: boolean;
  vertex_count?: number;
  edge_count?: number;
  snapshot_num?: number;
  is_expand?: boolean;
  children?: DBRow[];
};

export default function Database({ group }: { group: WorkGroupT }) {
  const { workspaces } = group;
  const [css] = useStyletron();

  const [databases, setDatabases] = useState(() => {
    const databases: DBRow[] = [];
    const groupedWorkspaces = groupWorkSpacesByDatabase(group);
    for (let groupedWorkspace of groupedWorkspaces) {
      let { database, workspaces } = groupedWorkspace;
      if (!database) {
        continue;
      }

      const mainWorkspace = workspaces.find((w) => w.is_rw);

      const children: DBRow[] = [];
      for (let workspace of workspaces) {
        if (workspace.is_rw) {
          continue;
        }
        children.push({
          ...database,
          is_rw: false,
          create_time: workspace.created_at,
          vertex_count: workspace.vertex_count,
          edge_count: workspace.edge_count,
        });
      }

      if (mainWorkspace) {
        databases.push({
          ...database,
          is_rw: true,
          vertex_count: mainWorkspace.vertex_count,
          edge_count: mainWorkspace.edge_count,
          snapshot_num: workspaces.length - 1,
          children,
        });
      } else {
        databases.push({
          ...database,
          is_rw: true,
          // default value to 0
          vertex_count: 0,
          edge_count: 0,
          snapshot_num: workspaces.length,
          children,
        });
      }
    }
    return databases;
  });

  const onToggle = (row: DBRow) => {
    setDatabases((databases) => {
      const newDatabases: DBRow[] = [];
      for (let i = 0; i < databases.length; i++) {
        const database = databases[i];
        if (database.database_id === row.database_id) {
          let { children = [], is_expand } = database;

          is_expand = !is_expand;
          newDatabases.push({
            ...database,
            is_expand,
          });

          if (is_expand) {
            for (let child of children) {
              newDatabases.push(child);
            }
          } else {
            // remove children
            i += children.length;
          }
        } else {
          newDatabases.push(database);
        }
      }

      return newDatabases;
    });
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      })}
    >
      <StyledToast
        kind="info"
        message="Please be aware that the total vertex and edge counts displayed may not reflect real-time data."
        size="compact"
        closeable={false}
      />
      <TableBuilder data={databases}>
        <TableBuilderColumn
          header=""
          id="expand"
          overrides={{
            TableBodyCell: {
              style: {
                width: '20px',
              },
            },
          }}
        >
          {(row: DBRow) => {
            if (row.is_rw) {
              return (
                row.snapshot_num! > 0 && (
                  <IconButton onClick={() => onToggle(row)}>
                    {row.is_expand ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                  </IconButton>
                )
              );
            }
            return undefined;
          }}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="Database Name"
          id="name"
          overrides={{
            TableBodyCell: {
              style: {
                width: '250px',
              },
            },
          }}
        >
          {(row: DBRow) => {
            if (row.is_rw) {
              return (
                <div
                  className={css({
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  })}
                >
                  {row.name}
                  {row.snapshot_num! > 0 && (
                    <span
                      className={css({
                        display: 'flex',
                        alignItems: 'center',
                      })}
                    >
                      <BranchIcon />
                      <Label>{row.snapshot_num}</Label>
                    </span>
                  )}
                </div>
              );
            }

            return (
              <div
                className={css({
                  paddingLeft: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                })}
              >
                <span>{row.name}</span>
                <Tag closeable={false} kind="neutral">
                  Snapshot
                </Tag>
              </div>
            );
          }}
        </TableBuilderColumn>
        <TableBuilderColumn header="Region" id="region">
          {() => group.region}
        </TableBuilderColumn>
        <TableBuilderColumn header="Total Size" id="totalSize">
          {(row: DBRow) => {
            if (row.is_rw) {
              return (
                <span
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  })}
                >
                  <span>{formatBytes(row.folder_statistics?.billed_size)}</span>
                  {row.folder_statistics?.billed_size ? (
                    <StatefulTipsPopover
                      content="The database size includes the size of backups."
                      triggerType={TRIGGER_TYPE.hover}
                      placement={PLACEMENT.right}
                    >
                      <IconButton
                        className={css({
                          marginRight: 'auto',
                        })}
                      >
                        <Info size={16} />
                      </IconButton>
                    </StatefulTipsPopover>
                  ) : null}
                </span>
              );
            }

            // not show size for snapshot
            return '';
          }}
        </TableBuilderColumn>
        <TableBuilderColumn header="Total Vertex" id="totalVertex">
          {(row: DBRow) => formatCount(row.vertex_count)}
        </TableBuilderColumn>
        <TableBuilderColumn header="Total Edge" id="totalEdge">
          {(row: DBRow) => formatCount(row.edge_count)}
        </TableBuilderColumn>
        <TableBuilderColumn header="Create Date" id="date">
          {(row) => {
            return format(parseDate(row.create_time), 'yyyy-MM-dd HH:mm:ss');
          }}
        </TableBuilderColumn>
        <TableBuilderColumn overrides={actionColumnOverrides} header="Actions">
          {(row) => (
            <div
              className={clsx(
                css({
                  display: 'flex',
                  gap: '6px',
                })
                // 'hoverToShow'
              )}
            >
              <SettingMenuForDatabase
                database={row}
                disableDelete={workspaces.some((w) => w.database_id === row.database_id)}
              />
            </div>
          )}
        </TableBuilderColumn>
      </TableBuilder>
    </div>
  );
}
