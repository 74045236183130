import { Result } from '@/lib/type';
import CloudProviderCategory, { CloudPlatform } from '@/pages/admin/settings/cloud_provider/CloudProviderCategory';
import { Subtitle } from '@/pages/admin/settings/cloud_provider/StyledComponents';
import { IFormInput } from '@/pages/admin/settings/cloud_provider/types';
import { getMeta } from '@/pages/workgroup/api';
import { WorkSpaceMeta } from '@/pages/workgroup/type';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Select } from '@tigergraph/app-ui-lib/select';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useQuery } from 'react-query';

interface SelectVendorProps {
  cloudPlatform: CloudPlatform;
  control: Control<IFormInput, any>;
  errors: FieldErrors<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
  watch: UseFormWatch<IFormInput>;
}

export default function SelectVendor({ cloudPlatform, control, errors, setValue, watch }: SelectVendorProps) {
  const [css, theme] = useStyletron();

  const cloudPlatforms: CloudPlatform[] = [CloudPlatform.AWS, CloudPlatform.GCP, CloudPlatform.Azure];

  const metaQuery = useQuery<Result<WorkSpaceMeta>, AxiosError>(['workspaceMeta'], getMeta);
  const regionOps = (metaQuery.data?.Result?.regions || []).map((region) => ({ id: region.name, label: region.name }));

  const region = watch('region');

  useEffect(() => {
    if (regionOps.length && !region) {
      setValue('region', regionOps[0].id);
    }
  }, [regionOps, region, setValue]);

  return (
    <>
      <Subtitle>Name</Subtitle>
      <FormControl label="" error={errors?.name?.message}>
        <Controller
          rules={{
            required: 'Required',
          }}
          control={control}
          name="name"
          render={({ field }) => (
            <Input id="name" placeholder="Enter cloud provider name" {...field} error={!!errors?.name} />
          )}
        />
      </FormControl>

      <Subtitle>Select Cloud Vendor</Subtitle>
      <div className={css({ display: 'flex', gap: '12px', marginBottom: '16px' })}>
        {cloudPlatforms.map((name) => (
          <CloudProviderCategory
            key={name}
            disabled={name != 'AWS'}
            selected={name == cloudPlatform}
            platform={name}
            onSelect={() => {}}
          />
        ))}
      </div>

      <Subtitle>Region</Subtitle>
      <FormControl>
        <Controller
          control={control}
          name="region"
          render={({ field: { value, onChange, ref, ...field } }) => (
            <Select
              options={regionOps}
              value={[{ id: value }]}
              onChange={(params) => {
                onChange(params.value[0].id);
              }}
              clearable={false}
              inputRef={ref}
              {...field}
            />
          )}
        />
      </FormControl>
    </>
  );
}
