import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { Tabs as BaseTabs, TabsProps } from '@tigergraph/app-ui-lib/tab';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';
import { Tab, TabOverrides, TabsOverrides } from 'baseui/tabs-motion';
import { expand } from 'inline-style-expand-shorthand';
import { flatten } from 'lodash-es';

export { Tab } from 'baseui/tabs-motion';

export const pageTabsOverrides: TabsOverrides = {
  TabList: {
    style: {
      paddingTop: '16px',
    },
  },
  TabHighlight: {
    style: {
      display: 'none',
    },
  },
};

export const pageTabOverrides: TabOverrides = {
  Tab: {
    style: {
      ':hover': {
        background: 'transparent',
      },
      cursor: 'default',
    },
  },
};

export const pageTabOverridesNoPadding: TabOverrides = {
  Tab: {
    style: {
      ':hover': {
        background: 'transparent',
      },
      cursor: 'default',
    },
  },
  TabPanel: {
    style: {
      ...expand({
        padding: '0',
      }),
    },
  },
};

const tabsOverrides: TabsOverrides = {
  Root: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors['background.secondary'],
      };
    },
  },
};

const tabOverrides: TabOverrides = {
  Tab: {
    style: {
      whiteSpace: 'nowrap',
    },
  },
  TabPanel: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        height: 0,
        flexBasis: 0,
        flexGrow: 1,
        overflowY: 'auto',
        backgroundColor: theme.colors['background.primary'],
      };
    },
  },
};

// customize tab globally:  https://github.com/uber/baseweb/issues/3548
export function Tabs({ overrides, children, ...props }: TabsProps) {
  // we should not use Children.map method here, as it will break Tab key and init tab selection.
  // https://www.smashingmagazine.com/2021/08/react-children-iteration-methods/
  if (!Array.isArray(children)) {
    children = [children];
  }

  children = flatten(children as any[]);
  let tabs = (children as any[]).map((child) => {
    if (!child || !child.props) {
      return null;
    }
    let { overrides, ...props } = child.props;
    return (
      <Tab
        {...props}
        key={child.key}
        overrides={mergeOverrides(tabOverrides as Overrides<any>, overrides as Overrides<any>)}
      />
    );
  });

  return (
    <BaseTabs {...props} overrides={mergeOverrides(tabsOverrides as Overrides<any>, overrides as Overrides<any>)}>
      {tabs}
    </BaseTabs>
  );
}
