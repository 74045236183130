import { continuedIndent, indentNodeProp, foldNodeProp, LRLanguage, LanguageSupport } from '@codemirror/language';
import { styleTags, tags as t } from '@lezer/highlight';
import { parser as baseParser } from './gsql.grammar.ts';
import { tokens } from './tokens';
import { controlKeywords, keywords, types, accums, atoms } from './keywords';
import { completeFromBaseTypes, completeFromAccumTypes, completeFromIdents, completeFromKeywords } from './complete';
import { Schema } from '@tigergraph/tools-ui/graph/type';

const parser = baseParser.configure({
  props: [
    indentNodeProp.add({
      Statement: continuedIndent(),
    }),
    foldNodeProp.add({
      Statement(tree, state) {
        return { from: Math.min(tree.from + 100, state.doc.lineAt(tree.from).to), to: tree.to };
      },
      blockComment(tree) {
        return { from: tree.from + 2, to: tree.to - 2 };
      },
    }),
    styleTags({
      [controlKeywords.join(' ')]: t.controlKeyword,
      [keywords.join(' ')]: t.keyword,
      [types.join(' ')]: t.typeName,
      [accums.join(' ')]: t.typeName,
      [atoms.join(' ')]: t.atom,
      // [functions.join(' ')]: t.function(t.name),
      // [operators]: t.operator,
      'TRUE FALSE': t.bool,
      Null: t.null,
      Number: t.number,
      String: t.string,
      Identifier: t.name,
      GlobalAccumIdent: t.name,
      LocalAccumIdent: t.name,
      LineComment: t.lineComment,
      BlockComment: t.blockComment,
      ArithOp: t.arithmeticOperator,
      LogicOp: t.logicOperator,
      BitOp: t.bitwiseOperator,
      CompareOp: t.compareOperator,
      '; , :': t.separator,
      '.': t.derefOperator,
      '( )': t.paren,
      '{ }': t.brace,
      '[ ]': t.squareBracket,
      '< >': t.angleBracket,
    }),
  ],
});

export interface GSQLConfig {
  schema?: Schema;
}

export function gsql(config: GSQLConfig = {}) {
  const language = LRLanguage.define({
    name: 'gsql',
    parser: parser.configure({
      tokenizers: [{ from: tokens, to: tokens }],
    }),
    languageData: {
      commentTokens: { line: '//', block: { open: '/*', close: '*/' } },
      closeBrackets: { brackets: ['(', '[', '{', "'", '"', '`', '<'] },
    },
  });

  const keywordCompletion = language.data.of({
    autocomplete: completeFromKeywords(),
  });
  const identCompletion = language.data.of({
    autocomplete: completeFromIdents(),
  });
  const accumCompletion = language.data.of({
    autocomplete: completeFromAccumTypes(),
  });
  const baseTypeCompletion = language.data.of({
    autocomplete: completeFromBaseTypes(),
  });

  return new LanguageSupport(language, [baseTypeCompletion, accumCompletion, identCompletion, keywordCompletion]);
}
