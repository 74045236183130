// This file was generated by lezer-generator. You probably shouldn't edit it.
export const whitespace = 347,
  LineComment = 1,
  BlockComment = 2,
  String = 3,
  Bits = 4,
  Bytes = 5,
  Number = 6,
  Bool = 7,
  ParenL = 8,
  ParenR = 9,
  BraceL = 10,
  BraceR = 11,
  BracketL = 12,
  BracketR = 13,
  Dot = 14,
  Identifier = 15,
  GRAPH = 16,
  PRINT = 17,
  LOG = 18,
  RETURNS = 19,
  EXCEPTION = 20,
  SELECT = 21,
  FROM = 22,
  WHERE = 23,
  SAMPLE = 24,
  WHEN = 25,
  ACCUM = 26,
  HAVING = 28,
  ASC = 29,
  DESC = 30,
  LIMIT = 31,
  OFFSET = 32,
  DELETE = 33,
  INSERT = 34,
  VALUES = 35,
  UPDATE = 36,
  IN = 37,
  RANGE = 38,
  TYPEDEF = 39,
  TUPLE = 40,
  STATIC = 41,
  OR = 42,
  REPLACE = 43,
  QUERY = 44,
  BATCH = 45,
  ANY = 46,
  API = 47,
  AS = 48,
  BOTH = 49,
  BY = 50,
  DISTINCT = 51,
  FILTER = 52,
  INTERPRET = 53,
  INTERVAL = 54,
  INTO = 55,
  ISEMPTY = 56,
  LASTHOP = 57,
  LEADING = 58,
  LOADACCUM = 59,
  PER = 60,
  PINNED = 61,
  POST_ACCUM = 62,
  TARGET = 63,
  TO_CSV = 64,
  TRAILING = 65,
  COMPRESS = 66,
  CREATE = 67,
  DISTRIBUTED = 68,
  FOR = 69,
  GROUP = 70,
  IS = 71,
  LIST = 72,
  MAP = 73,
  MATCH = 74,
  ORDER = 75,
  PATH = 76,
  CHECK = 77,
  CLOB = 78,
  CONSTRAINT = 79,
  CONST = 80,
  CURRENT_DATE = 81,
  CURRENT_TIME = 82,
  CURRENT_TIMESTAMP = 83,
  CURSOR = 84,
  DECLARE = 85,
  ELSEIF = 86,
  EXISTS = 87,
  HEADER = 88,
  IGNORE = 89,
  INPUT_LINE_FILTER = 90,
  JOB = 91,
  JOIN = 92,
  KAFKA = 93,
  KEY = 94,
  LOAD = 95,
  LONG = 96,
  NOBODY = 97,
  ON = 98,
  PRIMARY = 99,
  PROXY = 100,
  QUIT = 101,
  REDUCE = 102,
  RESET_COLLECTION_ACCUM = 103,
  S3 = 104,
  UPSERT = 105,
  USING = 106,
  WITH = 107,
  INDEX = 108,
  TRANSLATESQL = 109,
  TEMP_TABLE = 110,
  ACL = 111,
  APPROX_COUNT = 112,
  ATTRIBUTE = 113,
  BEGIN = 114,
  BITINDEX = 115,
  CHANGE = 116,
  CONCAT = 117,
  DATA = 118,
  DATASRC = 119,
  DECRYPT = 120,
  DEFAULT_ = 121,
  DEFINE = 122,
  DESCRIPTION = 123,
  DIRECTED = 124,
  EMPTY = 125,
  EXECUTE = 126,
  EXIT = 127,
  EXPORT = 128,
  TG_EXPR_FUNC = 129,
  TG_EXPR_UTIL = 130,
  EXPR_FUNC = 131,
  EXPR_UTIL = 132,
  EXTERN = 133,
  FILENAMEVAR = 134,
  FLATTEN = 135,
  FLATTENJSON = 136,
  GENERATEDATA = 137,
  GET = 138,
  GRANT = 139,
  HELP = 140,
  ICON = 141,
  IMPORT = 142,
  INSTALL = 143,
  JSON = 144,
  LEADER = 145,
  LOADING = 146,
  LOCAL = 147,
  LS = 148,
  NUMERIC = 149,
  OF = 150,
  OPTION = 151,
  OVERWRITE = 152,
  OWNER = 153,
  PAIR = 154,
  PASSWORD = 155,
  PRIVILEGE = 156,
  PUT = 157,
  READ = 158,
  RECOMPILE = 159,
  REJECT_LINE_RULE = 160,
  RESUME = 161,
  REVOKE = 162,
  ROLE = 163,
  SCHEMA = 164,
  SCHEMA_CHANGE = 165,
  SECONDARY_ID = 166,
  SECURED = 167,
  SEPARATOR = 168,
  SHOW = 169,
  SPLIT = 170,
  STATS = 171,
  STATUS = 172,
  STORE = 173,
  SUBSTR = 174,
  TAG = 175,
  TAGS = 176,
  TEMPLATE = 177,
  TK = 178,
  TOKENLEN = 179,
  TOKEN_BANK = 180,
  TOFLOAT = 181,
  TOINT = 182,
  UNDIRECTED = 183,
  USE = 184,
  VAL = 185,
  VECTOR = 186,
  VERSION = 187,
  VOID = 188,
  SINGLE = 189,
  LEGACY = 190,
  SYNTAX = 191,
  GSQL_UINT_MAX = 192,
  GSQL_INT_MAX = 193,
  GSQL_INT_MIN = 194,
  TO_DATETIME = 195,
  TRUE = 196,
  FALSE = 197,
  IF = 198,
  THEN = 199,
  ELSE = 200,
  WHILE = 201,
  DO = 202,
  FOREACH = 203,
  END = 204,
  CASE = 205,
  CONTINUE = 206,
  BREAK = 207,
  TRY = 208,
  CATCH = 209,
  RAISE = 210,
  RETURN = 211,
  ABORT = 212,
  INT = 213,
  UINT = 214,
  FLOAT = 215,
  DOUBLE = 216,
  STRING = 217,
  BOOL = 218,
  VERTEX = 219,
  EDGE = 220,
  JSONOBJECT = 221,
  JSONARRAY = 222,
  SET = 223,
  BAG = 224,
  FILE = 225,
  DATETIME = 226,
  SumAccum = 227,
  MaxAccum = 228,
  MinAccum = 229,
  AvgAccum = 230,
  OrAccum = 231,
  AndAccum = 232,
  BitwiseOrAccum = 233,
  BitwiseAndAccum = 234,
  ListAccum = 235,
  SetAccum = 236,
  BagAccum = 237,
  MapAccum = 238,
  HeapAccum = 239,
  GroupByAccum = 240,
  ArrayAccum = 241,
  COUNT = 242,
  MAX = 243,
  MIN = 244,
  AVG = 245,
  SUM = 246,
  Script = 247,
  Statement = 248,
  CreateQuery = 249,
  CreateQueryModifiers = 250,
  ParameterList = 251,
  Parameter = 252,
  ParameterType = 253,
  BaseType = 254,
  Constant = 258,
  GraphOption = 260,
  ReturnsOption = 261,
  APIOption = 262,
  SyntaxOption = 263,
  QueryBodyStmts = 264,
  TypedefStmt = 265,
  VSetVarDeclStmt = 266,
  Seeds = 267,
  VertexSet = 268,
  AssignmentStmt = 270,
  GlobalAccumIdent = 271,
  LocalAccumIdent = 272,
  Element = 273,
  Operator = 274,
  Type = 281,
  AccumType = 282,
  ElementType = 283,
  DeclStmt = 286,
  BaseDeclStmt = 287,
  AccumDeclStmt = 288,
  FileDeclStmt = 289,
  FuncCallStmt = 290,
  CaseStmt = 291,
  ForEachStmt = 292,
  RaiseStmt = 293,
  TryStmt = 294,
  LogStmt = 295,
  SelectStmt = 296,
  GSQLSelectClause = 297,
  FromClause = 298,
  SampleClause = 299,
  WhereClause = 300,
  AccumClause = 301,
  DmlSubStmtList = 302,
  LocalVarDeclStmt = 303,
  DmlSubCaseStmt = 304,
  DmlSubWhileStmt = 305,
  DmlSubIfStmt = 306,
  DmlSubForEachStmt = 307,
  InsertStmt = 308,
  DmlSubDeleteStmt = 309,
  PostAccumClause = 310,
  HavingClause = 311,
  OrderClause = 312,
  LimitClause = 313,
  SQLSelectClause = 314,
  Aggregator = 315,
  GroupByClause = 316,
  PrintStmt = 317,
  ReturnStmt = 318,
  WhileStmt = 319,
  IfStmt = 320,
  DeleteStmt = 321,
  UpdateStmt = 322,
  UseGraphStmt = 323;
