import { ExternalTokenizer, InputStream } from '@lezer/lr';
import {
  whitespace,
  LineComment,
  BlockComment,
  String as StringToken,
  Number,
  ParenR,
  BraceL,
  BraceR,
  BracketL,
  BracketR,
  Dot,
  Identifier,
  GRAPH,
  PRINT,
  LOG,
  RETURNS,
  EXCEPTION,
  SELECT,
  FROM,
  WHERE,
  SAMPLE,
  WHEN,
  ACCUM,
  POST_ACCUM,
  HAVING,
  ASC,
  DESC,
  LIMIT,
  OFFSET,
  DELETE,
  INSERT,
  VALUES,
  UPDATE,
  IN,
  RANGE,
  TYPEDEF,
  TUPLE,
  STATIC,
  OR,
  REPLACE,
  QUERY,
  BATCH,
  ANY,
  API,
  AS,
  BOTH,
  BY,
  DISTINCT,
  FILTER,
  INTERPRET,
  INTERVAL,
  INTO,
  ISEMPTY,
  LASTHOP,
  LEADING,
  LOADACCUM,
  PER,
  PINNED,
  TARGET,
  TO_CSV,
  TRAILING,
  COMPRESS,
  CREATE,
  DISTRIBUTED,
  FOR,
  GROUP,
  IS,
  LIST,
  MAP,
  MATCH,
  ORDER,
  PATH,
  CHECK,
  CLOB,
  CONSTRAINT,
  CONST,
  CURRENT_DATE,
  CURRENT_TIME,
  CURRENT_TIMESTAMP,
  CURSOR,
  DECLARE,
  ELSEIF,
  EXISTS,
  HEADER,
  IGNORE,
  INPUT_LINE_FILTER,
  JOB,
  JOIN,
  KAFKA,
  KEY,
  LOAD,
  LONG,
  NOBODY,
  ON,
  PRIMARY,
  PROXY,
  QUIT,
  REDUCE,
  RESET_COLLECTION_ACCUM,
  S3,
  UPSERT,
  USING,
  WITH,
  INDEX,
  TRANSLATESQL,
  TEMP_TABLE,
  ACL,
  APPROX_COUNT,
  ATTRIBUTE,
  BEGIN,
  BITINDEX,
  CHANGE,
  CONCAT,
  DATA,
  DATASRC,
  DECRYPT,
  DEFAULT_,
  DEFINE,
  DESCRIPTION,
  DIRECTED,
  EMPTY,
  EXECUTE,
  EXIT,
  EXPORT,
  TG_EXPR_FUNC,
  TG_EXPR_UTIL,
  EXPR_FUNC,
  EXPR_UTIL,
  EXTERN,
  FILENAMEVAR,
  FLATTEN,
  FLATTENJSON,
  GENERATEDATA,
  GET,
  GRANT,
  HELP,
  ICON,
  IMPORT,
  INSTALL,
  JSON,
  LEADER,
  LOADING,
  LOCAL,
  LS,
  NUMERIC,
  OF,
  OPTION,
  OVERWRITE,
  OWNER,
  PAIR,
  PASSWORD,
  PRIVILEGE,
  PUT,
  READ,
  RECOMPILE,
  REJECT_LINE_RULE,
  RESUME,
  REVOKE,
  ROLE,
  SCHEMA,
  SCHEMA_CHANGE,
  SECONDARY_ID,
  SECURED,
  SEPARATOR,
  SHOW,
  SPLIT,
  STATS,
  STATUS,
  STORE,
  SUBSTR,
  TAG,
  TAGS,
  TEMPLATE,
  TK,
  TOKENLEN,
  TOKEN_BANK,
  TOFLOAT,
  TOINT,
  UNDIRECTED,
  USE,
  VAL,
  VECTOR,
  VERSION,
  VOID,
  SINGLE,
  LEGACY,
  IF,
  THEN,
  ELSE,
  WHILE,
  DO,
  FOREACH,
  END,
  CASE,
  CONTINUE,
  BREAK,
  TRY,
  CATCH,
  RAISE,
  RETURN,
  ABORT,
  SYNTAX,
  GSQL_UINT_MAX,
  GSQL_INT_MAX,
  GSQL_INT_MIN,
  TO_DATETIME,
  INT,
  UINT,
  FLOAT,
  DOUBLE,
  STRING,
  BOOL,
  VERTEX,
  EDGE,
  JSONOBJECT,
  JSONARRAY,
  SET,
  BAG,
  FILE,
  DATETIME,
  SumAccum,
  MaxAccum,
  MinAccum,
  AvgAccum,
  OrAccum,
  AndAccum,
  BitwiseOrAccum,
  BitwiseAndAccum,
  ListAccum,
  SetAccum,
  BagAccum,
  MapAccum,
  HeapAccum,
  GroupByAccum,
  ArrayAccum,
  TRUE,
  FALSE,
  COUNT,
  MAX,
  MIN,
  AVG,
  SUM,
  ParenL,
} from './gsql.grammar.terms';

const enum Ch {
  Newline = 10,
  Space = 32,
  DoubleQuote = 34,
  Hash = 35,
  Dollar = 36,
  SingleQuote = 39,
  ParenL = 40,
  ParenR = 41,
  Star = 42,
  Plus = 43,
  Comma = 44,
  Dash = 45,
  Dot = 46,
  Slash = 47,
  Colon = 58,
  Semi = 59,
  Question = 63,
  At = 64,
  BracketL = 91,
  BracketR = 93,
  Backslash = 92,
  Underscore = 95,
  Backtick = 96,
  BraceL = 123,
  BraceR = 125,

  A = 65,
  a = 97,
  B = 66,
  b = 98,
  E = 69,
  e = 101,
  F = 70,
  f = 102,
  N = 78,
  n = 110,
  Q = 81,
  q = 113,
  X = 88,
  x = 120,
  Z = 90,
  z = 122,

  _0 = 48,
  _1 = 49,
  _9 = 57,
}

function isAlpha(ch: number) {
  return (ch >= Ch.A && ch <= Ch.Z) || (ch >= Ch.a && ch <= Ch.z) || (ch >= Ch._0 && ch <= Ch._9);
}

function isHexDigit(ch: number) {
  return (ch >= Ch._0 && ch <= Ch._9) || (ch >= Ch.a && ch <= Ch.f) || (ch >= Ch.A && ch <= Ch.F);
}

function readLiteral(input: InputStream, endQuote: number, backslashEscapes: boolean) {
  for (let escaped = false; ; ) {
    if (input.next < 0) return;
    if (input.next == endQuote && !escaped) {
      input.advance();
      return;
    }
    escaped = backslashEscapes && !escaped && input.next == Ch.Backslash;
    input.advance();
  }
}

function readWord(input: InputStream, result?: string) {
  for (;;) {
    if (input.next != Ch.Underscore && !isAlpha(input.next)) break;
    if (result !== undefined) result += String.fromCharCode(input.next);
    input.advance();
  }
  return result;
}

function readNumber(input: InputStream, sawDot: boolean) {
  for (;;) {
    if (input.next == Ch.Dot) {
      if (sawDot) break;
      sawDot = true;
    } else if (input.next < Ch._0 || input.next > Ch._9) {
      break;
    }
    input.advance();
  }
  if (input.next == Ch.E || input.next == Ch.e) {
    input.advance();
    if ((input as any).next == Ch.Plus || (input as any).next == Ch.Dash) input.advance();
    while (input.next >= Ch._0 && input.next <= Ch._9) input.advance();
  }
}

function eol(input: InputStream) {
  while (!(input.next < 0 || input.next == Ch.Newline)) input.advance();
}

function inString(ch: number, str: string) {
  for (let i = 0; i < str.length; i++) if (str.charCodeAt(i) == ch) return true;
  return false;
}

const Space = ' \t\r\n';

const ignoreCaseKwMap: Record<string, number> = {
  TRUE: TRUE,
  FALSE: FALSE,
  // keywords
  GRAPH: GRAPH,
  PRINT: PRINT,
  LOG: LOG,
  RETURNS: RETURNS,
  EXCEPTION: EXCEPTION,
  SELECT: SELECT,
  FROM: FROM,
  WHERE: WHERE,
  SAMPLE: SAMPLE,
  WHEN: WHEN,
  ACCUM: ACCUM,
  'POST-ACCUM': POST_ACCUM,
  HAVING: HAVING,
  ASC: ASC,
  DESC: DESC,
  LIMIT: LIMIT,
  OFFSET: OFFSET,
  DELETE: DELETE,
  INSERT: INSERT,
  VALUES: VALUES,
  UPDATE: UPDATE,
  IN: IN,
  RANGE: RANGE,
  TYPEDEF: TYPEDEF,
  TUPLE: TUPLE,
  STATIC: STATIC,
  OR: OR,
  REPLACE: REPLACE,
  QUERY: QUERY,
  BATCH: BATCH,
  ANY: ANY,
  API: API,
  AS: AS,
  BOTH: BOTH,
  BY: BY,
  DISTINCT: DISTINCT,
  FILTER: FILTER,
  INTERPRET: INTERPRET,
  INTERVAL: INTERVAL,
  INTO: INTO,
  ISEMPTY: ISEMPTY,
  LASTHOP: LASTHOP,
  LEADING: LEADING,
  LOADACCUM: LOADACCUM,
  PER: PER,
  PINNED: PINNED,
  POST_ACCUM: POST_ACCUM,
  TARGET: TARGET,
  TO_CSV: TO_CSV,
  TRAILING: TRAILING,
  COMPRESS: COMPRESS,
  CREATE: CREATE,
  DISTRIBUTED: DISTRIBUTED,
  FOR: FOR,
  GROUP: GROUP,
  IS: IS,
  LIST: LIST,
  MAP: MAP,
  MATCH: MATCH,
  ORDER: ORDER,
  PATH: PATH,
  CHECK: CHECK,
  CLOB: CLOB,
  CONSTRAINT: CONSTRAINT,
  CONST: CONST,
  CURRENT_DATE: CURRENT_DATE,
  CURRENT_TIME: CURRENT_TIME,
  CURRENT_TIMESTAMP: CURRENT_TIMESTAMP,
  CURSOR: CURSOR,
  DECLARE: DECLARE,
  ELSEIF: ELSEIF,
  EXISTS: EXISTS,
  HEADER: HEADER,
  IGNORE: IGNORE,
  INPUT_LINE_FILTER: INPUT_LINE_FILTER,
  JOB: JOB,
  JOIN: JOIN,
  KAFKA: KAFKA,
  KEY: KEY,
  LOAD: LOAD,
  LONG: LONG,
  NOBODY: NOBODY,
  ON: ON,
  PRIMARY: PRIMARY,
  PROXY: PROXY,
  QUIT: QUIT,
  REDUCE: REDUCE,
  RESET_COLLECTION_ACCUM: RESET_COLLECTION_ACCUM,
  S3: S3,
  UPSERT: UPSERT,
  USING: USING,
  WITH: WITH,
  INDEX: INDEX,
  TRANSLATESQL: TRANSLATESQL,
  TEMP_TABLE: TEMP_TABLE,
  ACL: ACL,
  APPROX_COUNT: APPROX_COUNT,
  ATTRIBUTE: ATTRIBUTE,
  BEGIN: BEGIN,
  BITINDEX: BITINDEX,
  CHANGE: CHANGE,
  CONCAT: CONCAT,
  DATA: DATA,
  DATASRC: DATASRC,
  DECRYPT: DECRYPT,
  DEFAULT_: DEFAULT_,
  DEFINE: DEFINE,
  DESCRIPTION: DESCRIPTION,
  DIRECTED: DIRECTED,
  EMPTY: EMPTY,
  EXECUTE: EXECUTE,
  EXIT: EXIT,
  EXPORT: EXPORT,
  TG_EXPR_FUNC: TG_EXPR_FUNC,
  TG_EXPR_UTIL: TG_EXPR_UTIL,
  EXPR_FUNC: EXPR_FUNC,
  EXPR_UTIL: EXPR_UTIL,
  EXTERN: EXTERN,
  FILENAMEVAR: FILENAMEVAR,
  FLATTEN: FLATTEN,
  FLATTENJSON: FLATTENJSON,
  GENERATEDATA: GENERATEDATA,
  GET: GET,
  GRANT: GRANT,
  HELP: HELP,
  ICON: ICON,
  IMPORT: IMPORT,
  INSTALL: INSTALL,
  JSON: JSON,
  LEADER: LEADER,
  LOADING: LOADING,
  LOCAL: LOCAL,
  LS: LS,
  NUMERIC: NUMERIC,
  OF: OF,
  OPTION: OPTION,
  OVERWRITE: OVERWRITE,
  OWNER: OWNER,
  PAIR: PAIR,
  PASSWORD: PASSWORD,
  PRIVILEGE: PRIVILEGE,
  PUT: PUT,
  READ: READ,
  RECOMPILE: RECOMPILE,
  REJECT_LINE_RULE: REJECT_LINE_RULE,
  RESUME: RESUME,
  REVOKE: REVOKE,
  ROLE: ROLE,
  SCHEMA: SCHEMA,
  SCHEMA_CHANGE: SCHEMA_CHANGE,
  SECONDARY_ID: SECONDARY_ID,
  SECURED: SECURED,
  SEPARATOR: SEPARATOR,
  SHOW: SHOW,
  SPLIT: SPLIT,
  STATS: STATS,
  STATUS: STATUS,
  STORE: STORE,
  SUBSTR: SUBSTR,
  TAG: TAG,
  TAGS: TAGS,
  TEMPLATE: TEMPLATE,
  TK: TK,
  TOKENLEN: TOKENLEN,
  TOKEN_BANK: TOKEN_BANK,
  TOFLOAT: TOFLOAT,
  TOINT: TOINT,
  UNDIRECTED: UNDIRECTED,
  USE: USE,
  VAL: VAL,
  VECTOR: VECTOR,
  VERSION: VERSION,
  VOID: VOID,
  SINGLE: SINGLE,
  LEGACY: LEGACY,
  SYNTAX: SYNTAX,
  GSQL_UINT_MAX: GSQL_UINT_MAX,
  GSQL_INT_MAX: GSQL_INT_MAX,
  GSQL_INT_MIN: GSQL_INT_MIN,
  TO_DATETIME: TO_DATETIME,
  // control keywords
  IF: IF,
  THEN: THEN,
  ELSE: ELSE,
  WHILE: WHILE,
  DO: DO,
  FOREACH: FOREACH,
  END: END,
  CASE: CASE,
  CONTINUE: CONTINUE,
  BREAK: BREAK,
  TRY: TRY,
  CATCH: CATCH,
  RAISE: RAISE,
  RETURN: RETURN,
  ABORT: ABORT,
  // types
  INT: INT,
  UINT: UINT,
  FLOAT: FLOAT,
  DOUBLE: DOUBLE,
  STRING: STRING,
  BOOL: BOOL,
  VERTEX: VERTEX,
  EDGE: EDGE,
  JSONOBJECT: JSONOBJECT,
  JSONARRAY: JSONARRAY,
  SET: SET,
  BAG: BAG,
  FILE: FILE,
  DATETIME: DATETIME,
  COUNT: COUNT,
  MAX: MAX,
  MIN: MIN,
  AVG: AVG,
  SUM: SUM,
};

const keywordMap: Record<string, number> = {
  SumAccum: SumAccum,
  MaxAccum: MaxAccum,
  MinAccum: MinAccum,
  AvgAccum: AvgAccum,
  OrAccum: OrAccum,
  AndAccum: AndAccum,
  BitwiseOrAccum: BitwiseOrAccum,
  BitwiseAndAccum: BitwiseAndAccum,
  ListAccum: ListAccum,
  SetAccum: SetAccum,
  BagAccum: BagAccum,
  MapAccum: MapAccum,
  HeapAccum: HeapAccum,
  GroupByAccum: GroupByAccum,
  ArrayAccum: ArrayAccum,
};

export function keywords(name: string) {
  let found = ignoreCaseKwMap[name.toUpperCase()];
  if (found) return found;

  found = keywordMap[name];
  if (found) return found;

  return -1;
}

export function tokensFor() {
  return new ExternalTokenizer((input) => {
    const next = input.next;
    input.advance();
    if (inString(next, Space)) {
      while (inString(input.next, Space)) input.advance();
      input.acceptToken(whitespace);
    } else if (next == Ch.DoubleQuote) {
      readLiteral(input, next, true);
      input.acceptToken(StringToken);
    } else if (next == Ch.Hash || (next == Ch.Slash && input.next == Ch.Slash)) {
      eol(input);
      input.acceptToken(LineComment);
    } else if (next == Ch.Slash && input.next == Ch.Star) {
      input.advance();
      for (let depth = 1; ; ) {
        let cur: number = input.next;
        if (input.next < 0) break;
        input.advance();
        if (cur == Ch.Star && (input as any).next == Ch.Slash) {
          depth--;
          input.advance();
          if (!depth) break;
        } else if (cur == Ch.Slash && input.next == Ch.Star) {
          depth++;
          input.advance();
        }
      }
      input.acceptToken(BlockComment);
    } else if ((next == Ch.e || next == Ch.E) && input.next == Ch.SingleQuote) {
      input.advance();
      readLiteral(input, Ch.SingleQuote, true);
      input.acceptToken(StringToken);
    } else if (next == Ch.ParenL) {
      input.acceptToken(ParenL);
    } else if (next == Ch.ParenR) {
      input.acceptToken(ParenR);
    } else if (next == Ch.BraceL) {
      input.acceptToken(BraceL);
    } else if (next == Ch.BraceR) {
      input.acceptToken(BraceR);
    } else if (next == Ch.BracketL) {
      input.acceptToken(BracketL);
    } else if (next == Ch.BracketR) {
      input.acceptToken(BracketR);
    } else if (
      (next == Ch._0 && (input.next == Ch.x || input.next == Ch.X)) ||
      ((next == Ch.x || next == Ch.X) && input.next == Ch.SingleQuote)
    ) {
      let quoted = input.next == Ch.SingleQuote;
      input.advance();
      while (isHexDigit(input.next)) input.advance();
      if (quoted && input.next == Ch.SingleQuote) input.advance();
      input.acceptToken(Number);
    } else if (next == Ch.Dot && input.next >= Ch._0 && input.next <= Ch._9) {
      readNumber(input, true);
      input.acceptToken(Number);
    } else if (next == Ch.Dot) {
      input.acceptToken(Dot);
    } else if (next >= Ch._0 && next <= Ch._9) {
      readNumber(input, false);
      input.acceptToken(Number);
    } else if (isAlpha(next)) {
      const word = readWord(input, String.fromCharCode(next));
      // handle the case of "POST-ACCUM"
      if (word == 'POST' && input.next == Ch.Dash) {
        input.advance();
        readWord(input);
      }
      input.acceptToken(Identifier);
    }
  });
}

export const tokens = tokensFor();
