import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Plus } from 'baseui/icon';
import EmptyIcon from './icon/empty.svg';
import EmptyDarkIcon from './icon/empty-dark.svg';
import { useTheme } from '@/contexts/themeContext';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';
import { actionColumnOverrides } from '@/components/table';
import { Trash2Icon } from 'lucide-react';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import {
  useMutationCreateAPIKey,
  useMutationDeleteAPIKey,
  useQueryListAPIKey,
} from '@/pages/admin/settings/apikey/hook';
import { LoadingIndicator } from '@/components/loading-indicator';
import { ErrorDisplay } from '@/components/error';
import toast from 'react-hot-toast';
import { getErrorMessage } from '@/utils/utils';
import { parseDate } from '@/lib/date';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { Input } from '@tigergraph/app-ui-lib/input';
import { showToast, StyledToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { useEffect, useState } from 'react';
import { CopyIcon } from 'lucide-react';
import useCopyClipboard from 'react-use-clipboard';

export default function APIKey() {
  const [css] = useStyletron();

  const { data, isLoading, isError, error } = useQueryListAPIKey();

  const mutationDelete = useMutationDeleteAPIKey();

  const [showDialog, setShowDialog] = useState(false);

  const onDelete = (key_name: string) => {
    const promise = mutationDelete.mutateAsync({
      key_name,
    });

    toast.promise(
      promise,
      {
        loading: 'Deleting API key',
        success: (data) => data.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <ErrorDisplay label="Server error:" error={error} />;
  }

  const onAdd = () => {
    setShowDialog(true);
  };

  const keys = data?.Result || [];
  return (
    <div>
      {keys.length > 0 ? (
        <>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'flex-end',
            })}
          >
            <Button size="large" startEnhancer={<Plus size={20} />} onClick={onAdd}>
              Create API keys
            </Button>
          </div>
          <TableBuilder data={keys}>
            <TableBuilderColumn header="Name" id="name">
              {(row) => row.description}
            </TableBuilderColumn>
            <TableBuilderColumn header="API Key" id="key">
              {(row) => row.redacted_key}
            </TableBuilderColumn>
            <TableBuilderColumn header="Access" id="access">
              {(row) => 'Organization Admin'}
            </TableBuilderColumn>
            <TableBuilderColumn header="Created by" id="creator">
              {(row) => row.creator}
            </TableBuilderColumn>
            <TableBuilderColumn header="Created date" id="access">
              {(row) => {
                return format(parseDate(row.created_at), 'yyyy-MM-dd HH:mm:ss');
              }}
            </TableBuilderColumn>
            <TableBuilderColumn overrides={actionColumnOverrides} header="Actions">
              {(row) => (
                <div
                  className={css({
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  })}
                >
                  <ConfirmStatefulPopover
                    confirmLabel={`Are you sure you want to delete this key?`}
                    onConfirm={() => onDelete(row.key_name)}
                  >
                    <Button kind="text" shape="square">
                      <Trash2Icon size={16} />
                    </Button>
                  </ConfirmStatefulPopover>
                </div>
              )}
            </TableBuilderColumn>
          </TableBuilder>
        </>
      ) : (
        <EmptyState onClick={onAdd} />
      )}
      {showDialog ? <APIKeyDialog isOpen={showDialog} onClose={() => setShowDialog(false)} /> : null}
    </div>
  );
}

function EmptyState({ onClick }: { onClick: () => void }) {
  const [css, theme] = useStyletron();
  const { themeType } = useTheme();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        paddingTop: '156px',
        alignItems: 'center',
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.colors['text.secondary'],
        })}
      >
        <img src={themeType === 'dark' ? EmptyDarkIcon : EmptyIcon} />
        <div>{`Click “+ API keys” to configure cloud API keys.`}</div>
      </div>
      <Button size="large" startEnhancer={<Plus size={20} />} onClick={onClick}>
        Create API keys
      </Button>
    </div>
  );
}

type FormValue = {
  description: string;
};

function APIKeyDialog({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const mutationAdd = useMutationCreateAPIKey();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValue>({
    defaultValues: {
      description: '',
    },
  });

  const [css] = useStyletron();
  const [hasKey, setHasKey] = useState(false);
  const [apiKey, setAPIKey] = useState('');

  const [apiKeyCopied, setAPIKeyCopied] = useCopyClipboard(apiKey, {
    successDuration: 1000,
  });

  useEffect(() => {
    if (apiKeyCopied) {
      showToast({
        kind: 'positive',
        message: 'API key copied successfully.',
      });
    }
  }, [apiKeyCopied]);

  const onCreate = (data: FormValue) => {
    mutationAdd.mutate(data, {
      onSuccess(data) {
        if (data && data.Result) {
          setHasKey(true);
          setAPIKey(data.Result.plaintext_key);
        } else {
          showToast({
            kind: 'negative',
            message: `Fail to get key from server.`,
          });
        }
      },
      onError(error) {
        showToast({
          kind: 'negative',
          message: `${getErrorMessage(error)}`,
        });
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Create API key</ModalHeader>
      <ModalBody>
        <div
          className={css({
            marginBottom: '16px',
            fontSize: '14px',
          })}
        >{`Create an API key to access your organization's resources`}</div>
        <form>
          <FormControl label="API Key Name" error={errors?.description?.message}>
            <Controller
              rules={{
                required: 'required',
              }}
              control={control}
              name="description"
              render={({ field }) => (
                <Input
                  placeholder="API key"
                  {...field}
                  error={!!errors?.description}
                  disabled={hasKey}
                  autoComplete="off"
                />
              )}
            />
          </FormControl>
          {hasKey ? (
            <>
              <FormControl label="API Key" error={errors?.description?.message}>
                <div
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  })}
                >
                  <Input readOnly={hasKey} autoComplete="off" value={apiKey} disabled={true} />
                  <Button kind="text" type="button" onClick={setAPIKeyCopied}>
                    <CopyIcon size={18} />
                  </Button>
                </div>
              </FormControl>
              <StyledToast
                kind="info"
                hideBorder={true}
                title="Please save this now as you won't be able to view it again."
                closeable={false}
              />
            </>
          ) : null}
        </form>
      </ModalBody>
      <ModalFooter>
        {!hasKey ? (
          <>
            <ModalButton type="button" onClick={onClose} kind="secondary">
              Cancel
            </ModalButton>
            <ModalButton
              type="button"
              isLoading={mutationAdd.isLoading}
              disabled={mutationAdd.isLoading}
              onClick={handleSubmit((data) => {
                onCreate(data);
              })}
            >
              Create
            </ModalButton>
          </>
        ) : (
          <ModalButton type="button" onClick={onClose} kind="secondary">
            Close
          </ModalButton>
        )}
      </ModalFooter>
    </Modal>
  );
}
