import { Completion, CompletionContext, CompletionSource } from '@codemirror/autocomplete';
import { EditorState, Text } from '@codemirror/state';
import { syntaxTree } from '@codemirror/language';
import { SyntaxNode } from '@lezer/common';
import {
  types,
  accums,
  graphFunctions,
  functions,
  jsonFunctions,
  builtInAttrs,
  StmtNames,
  stmtKeywordsMap,
} from './keywords';
import { schemaCache } from '@/pages/editor/GSQL/schemaCache';

function tokenBefore(tree: SyntaxNode) {
  const cursor = tree.cursor().moveTo(tree.from, -1);
  while (/Comment/.test(cursor.name)) cursor.moveTo(cursor.from, -1);
  return cursor.node;
}

function idName(doc: Text, node: SyntaxNode): string {
  const text = doc.sliceString(node.from, node.to);
  const quoted = /^([`'"])(.*)\1$/.exec(text);
  return quoted ? quoted[2] : text;
}

function plainID(node: SyntaxNode | null) {
  return node && node.name == 'Identifier';
}

function parentsFor(doc: Text, node: SyntaxNode | null) {
  for (let path = []; ; ) {
    if (!node || node.name != '.') return path;
    const name = tokenBefore(node);
    if (!plainID(name)) return path;
    path.unshift(idName(doc, name));
    node = tokenBefore(name);
  }
}

function findParent(at: SyntaxNode, names: string[]) {
  for (let parent = at.parent; parent; parent = parent.parent) {
    if (names.includes(parent.name)) return parent;
  }
  return null;
}

interface SourceContext {
  from: number;
  parents: string[];
  aliases: Record<string, AliasType> | null;
  graphName: string;
}
function sourceContext(state: EditorState, startPos: number, at: SyntaxNode): SourceContext {
  let parents: string[] = [];
  let from = at.from;
  if (at.name === '.') {
    parents = parentsFor(state.doc, at);
    from = startPos;
  } else if (at.name === 'Identifier') {
    parents = parentsFor(state.doc, tokenBefore(at));
  }

  const graphName = getQueryGraphName(state.doc, at);
  const aliases = getAliases(state.doc, at, getVertexSetTypes(state.doc, at, getParamVertexTypes(state.doc, at)));

  return { from, parents, aliases, graphName };
}

function getQueryGraphName(doc: Text, at: SyntaxNode) {
  let statement;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return '';
    if (parent.name == 'CreateQuery') statement = parent;
  }
  const name = statement.getChild('GraphOption')?.getChild('Identifier');
  return name ? doc.sliceString(name.from, name.to) : '';
}

function noPrevSibling(node: SyntaxNode) {
  // no prev sibling or prev siblings are all comments
  for (let prev = node.prevSibling; prev; prev = prev.prevSibling) {
    if (prev.name !== 'LineComment' && prev.name !== 'BlockComment') return false;
  }
  return true;
}

function prevSibling(node: SyntaxNode) {
  for (let prev = node.prevSibling; prev; prev = prev.prevSibling) {
    if (prev.name !== 'LineComment' && prev.name !== 'BlockComment') return prev;
  }
  return null;
}

function getIdentNames(doc: Text, at: SyntaxNode): Record<'variable' | 'globalAccum' | 'localAccum', string[]> {
  let statement;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return { variable: [], globalAccum: [], localAccum: [] };
    if (parent.name == 'CreateQuery') statement = parent;
  }
  let varNames: string[] = [];
  let gAccumNames: string[] = [];
  let lAccumNames: string[] = [];
  const curText = doc.sliceString(at.from, at.to);

  const parameterList = statement.getChild('ParameterList');
  if (parameterList) {
    for (const param of parameterList.getChildren('Parameter')) {
      const paramName = param.getChild('ParameterType')?.nextSibling;
      if (paramName?.name === 'Identifier') {
        varNames.push(doc.sliceString(paramName.from, paramName.to));
      }
    }
  }

  const queryBodyStmts = statement.getChild('QueryBodyStmts');
  if (!queryBodyStmts) return { variable: [], globalAccum: [], localAccum: [] };

  for (const stmt of queryBodyStmts.getChildren('DeclStmt')) {
    const childStmt = stmt.firstChild!;
    if (childStmt.name === 'BaseDeclStmt') {
      const baseType = childStmt.getChild('BaseType')!;
      for (let node: SyntaxNode | null = baseType; node; node = node.nextSibling) {
        if (node.name === 'BaseType' || node.name === 'FILE' || node.name === ',') {
          if (node.nextSibling?.name === 'Identifier') {
            varNames.push(doc.sliceString(node.nextSibling.from, node.nextSibling.to));
            node = node.nextSibling;
          }
        }
      }
    }
  }

  // AssignmentStmt
  for (const assignment of queryBodyStmts.getChildren('AssignmentStmt')) {
    const childStmt = assignment.firstChild!;
    if (childStmt.name === 'Identifier') {
      varNames.push(doc.sliceString(childStmt.from, childStmt.to));
    }
  }

  for (const selectStmt of queryBodyStmts.getChildren('SelectStmt')) {
    const childStmt = selectStmt.firstChild!;
    if (childStmt.name === 'GSQLSelectClause' && childStmt.firstChild?.name === 'Identifier') {
      varNames.push(doc.sliceString(childStmt.firstChild.from, childStmt.firstChild.to));
    }
  }

  for (const vSetVarDeclStmt of queryBodyStmts.getChildren('VSetVarDeclStmt')) {
    const childStmt = vSetVarDeclStmt.firstChild!;
    if (childStmt.name === 'Identifier') {
      varNames.push(doc.sliceString(childStmt.from, childStmt.to));
    }
  }

  for (const accumDeclStmt of queryBodyStmts.getChildren('DeclStmt')) {
    const childStmt = accumDeclStmt.firstChild!;
    if (childStmt.name !== 'AccumDeclStmt') continue;

    const gAccumIdent = childStmt.getChildren('GlobalAccumIdent') || [];
    const lAccumIdent = childStmt.getChildren('LocalAccumIdent') || [];
    gAccumNames.push(...gAccumIdent.map((node) => doc.sliceString(node.from, node.to)));
    lAccumNames.push(...lAccumIdent.map((node) => doc.sliceString(node.from, node.to)));
  }

  return {
    variable: varNames.filter((v) => v !== curText),
    globalAccum: gAccumNames.filter((v) => v !== curText),
    localAccum: lAccumNames.filter((v) => v !== curText),
  };
}

function getParamVertexTypes(doc: Text, at: SyntaxNode): Record<string, string> {
  let statement;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return {};
    if (parent.name == 'CreateQuery') statement = parent;
  }
  const paramToVertexType: Record<string, string> = {};
  const paramList = statement.getChild('ParameterList');
  if (!paramList) return {};
  for (const param of paramList.getChildren('Parameter')) {
    const paramName = param.getChild('Identifier');
    const baseType = param.getChild('BaseType');
    if (paramName && baseType && baseType.firstChild?.name === 'VERTEX' && baseType.lastChild?.name === 'Identifier') {
      const vertexType = doc.sliceString(baseType.lastChild.from, baseType.lastChild.to);
      paramToVertexType[doc.sliceString(paramName.from, paramName.to)] = vertexType;
    }
  }
  return paramToVertexType;
}

type VertexSetType = string[] | 'UNKNOWN' | 'ANY';
type AliasType = string[] | 'UNKNOWN' | 'ANY';
function getVertexSetTypes(
  doc: Text,
  at: SyntaxNode,
  paramToVertexType: Record<string, string>
): Record<string, VertexSetType> {
  let statement;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return {};
    if (parent.name == 'CreateQuery') statement = parent;
  }
  const vSetTypeMap: Record<string, VertexSetType> = {};
  const declStmts = statement.getChild('QueryBodyStmts')?.getChildren('VSetVarDeclStmt') || [];
  for (const declStmt of declStmts) {
    const varNode = declStmt.getChild('Identifier');
    if (!varNode) continue;

    const varName = doc.sliceString(varNode.from, varNode.to);
    let vSetTypes: VertexSetType = [];
    const seeds = declStmt.getChild('Seeds');
    for (let seed = seeds?.firstChild; seed; seed = seed.nextSibling) {
      if (seed.name === 'VertexSet') {
        const seedText = doc.sliceString(seed.from, seed.to);
        if (seedText.endsWith('.*')) {
          // VertexType.*
          vSetTypes.push(seedText.slice(0, -2));
        } else {
          // paramName
          const paramVertexType = paramToVertexType[seedText];
          if (paramVertexType) {
            vSetTypes.push(paramVertexType);
          } else {
            vSetTypes = 'UNKNOWN';
            break;
          }
        }
      } else if (seed.name === 'ANY' || seed.name === '_') {
        vSetTypes = 'ANY';
        break;
      }
    }
    vSetTypeMap[varName] = vSetTypes;
  }

  return vSetTypeMap;
}

function getAliases(
  doc: Text,
  at: SyntaxNode,
  vSetTypeMap: Record<string, VertexSetType>
): Record<string, AliasType> | null {
  let statement;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return null;
    if (parent.name == 'SelectStmt' || parent.name == 'DeleteStmt' || parent.name == 'UpdateStmt') statement = parent;
  }
  const aliases: Record<string, AliasType> = {};

  const fromClause = statement.getChild('FromClause');
  if (!fromClause || fromClause.firstChild?.name !== 'FROM') return null;

  const fromClauseStr = doc
    .sliceString(fromClause.from, fromClause.to)
    .replace(/FROM/i, '')
    .replace(/(\/\/.*)|(\/\*[\s\S]*?\*\/)/g, '')
    .replace(/[\s()><]+/g, '');

  const fromClauseParts = fromClauseStr.split('-');
  for (const part of fromClauseParts) {
    const [vSetName, alias] = part.split(':');
    if (alias) {
      if (vSetName in vSetTypeMap) {
        aliases[alias] = vSetTypeMap[vSetName];
      } else if (['ANY', '_', ''].includes(vSetName.toUpperCase())) {
        aliases[alias] = 'ANY';
      } else {
        aliases[alias] = vSetName.split('|');
      }
    }
  }
  return aliases;
}

function completeFromGraphNames(at: SyntaxNode) {
  const graphNames = schemaCache.getGraphNames();
  if (!graphNames.length) return null;

  if (['GraphOption', 'UseGraphStmt'].includes(at.parent?.name || '') && at.prevSibling?.name === 'GRAPH') {
    return {
      from: at.from,
      to: undefined,
      options: graphNames.map((graphName) => ({ label: graphName, type: 'graph' })),
      validFor: /^\w*$/,
    };
  }

  return null;
}

export function completeFromIdents(): CompletionSource {
  return async (context: CompletionContext) => {
    const at = syntaxTree(context.state).resolveInner(context.pos, -1);
    if (!['Identifier', 'GlobalAccumIdent', 'LocalAccumIdent', '.'].includes(at?.name)) return null;

    const graphCompletion = completeFromGraphNames(at);
    if (graphCompletion) return graphCompletion;

    if (!findParent(at, ['QueryBodyStmts'])) {
      return null;
    }

    const { parents, from, aliases, graphName } = sourceContext(context.state, context.pos, at);
    const schemaCompletion = await schemaCache.getSchemaCompletion(graphName);

    let { variable: vars, globalAccum: gAccums, localAccum: lAccums } = getIdentNames(context.state.doc, at);

    let options: Completion[] = [];
    if (!parents.length) {
      options.push(...vars.map((v) => ({ label: v, type: 'variable' })));
      options.push(...gAccums.map((v) => ({ label: v, type: 'accum' })));

      let parent: SyntaxNode | null;
      const inVertexTypeCtx =
        (findParent(at, ['BaseType']) && at.prevSibling?.prevSibling?.name === 'VERTEX') ||
        findParent(at, ['Seeds']) ||
        ((parent = findParent(at, ['FromClause'])) && parent.firstChild?.name === 'FROM') ||
        ((parent = findParent(at, ['InsertStmt'])) && parent.firstChild?.name === 'INSERT');
      if (inVertexTypeCtx) {
        options.push(...(schemaCompletion?.getVertexEdgeCompletions() || []));
      } else {
        options.push(...Object.keys(aliases || {}).map((v) => ({ label: v, type: 'variable' })));
        if (at.parent?.name === 'Element') {
          options.push(...functions.map((v) => ({ label: v, type: 'function' })));
        }
      }
    } else if (parents.length == 1) {
      const alias = aliases && aliases[parents[0]];
      if (alias) {
        options.push(...lAccums.map((v) => ({ label: v, type: 'accum' })));
        options.push(...graphFunctions.map((v) => ({ label: v, type: 'function' })));
        options.push(...builtInAttrs.map((v) => ({ label: v, type: 'attribute' })));

        if (alias === 'ANY' || alias === 'UNKNOWN') {
          options.push(...(schemaCompletion?.getAllAttrCompletions() || []));
        } else if (Array.isArray(alias)) {
          options.push(...(schemaCompletion?.getAttrCompletions(alias) || []));
        }
      } else if (!findParent(at, ['VSetVarDeclStmt'])) {
        options.push(...jsonFunctions.map((v) => ({ label: v, type: 'function' })));
      }
    }

    return {
      from,
      to: undefined,
      options,
      validFor: /^@{0,2}\w*$/,
    };
  };
}

function wrapKwOptions(from: number, options: string[]) {
  return {
    from,
    to: undefined,
    options: options.map((keyword) => ({ label: keyword, type: 'keyword' })),
    validFor: /^[\w\-]*$/,
  };
}

function findInnerMostStmt(pos: SyntaxNode) {
  let parent: SyntaxNode | null = pos;
  while (parent && !StmtNames.includes(parent.name)) {
    parent = parent.parent;
  }
  return parent;
}

export function completeFromKeywords(): CompletionSource {
  // return ifNotIn(['String', 'LineComment', 'BlockComment', '.'], completeFromList(options));
  return (context: CompletionContext) => {
    const pos: SyntaxNode | null = syntaxTree(context.state).resolveInner(context.pos, -1);
    if (!pos || tokenBefore(pos).name === '.' || ['String', 'LineComment', 'BlockComment', '.'].includes(pos.name))
      return null;

    let keywords: string[] = [];
    // if outside query body
    const outsideQueryBody = !findParent(pos, ['QueryBodyStmts', 'ParameterList']);
    if (outsideQueryBody) {
      keywords = ['INTERPRET', 'CREATE', 'DISTRIBUTED', 'FOR', 'OR', 'REPLACE', 'QUERY', 'GRAPH', 'API', 'RETURNS'];
      const snippets = ['CREATE OR REPLACE', 'CREATE QUERY', 'INTERPRET QUERY'];
      return wrapKwOptions(pos.from, keywords.concat(snippets));
    }

    // GSQLSelectClause
    if (pos.parent?.parent?.name === 'AssignmentStmt' && pos.parent?.prevSibling?.name === 'AssignOp') {
      keywords = ['SELECT'];
      return wrapKwOptions(pos.from, keywords);
    }

    // 'ANY' in set seeds
    if (pos.parent?.name === 'Seeds' || pos.parent?.parent?.name === 'Seeds') {
      keywords = ['ANY'];
      return wrapKwOptions(pos.from, keywords);
    }

    // keywords can start a statement or sub-statement
    const beginOfStmt =
      ['QueryBodyStmts', 'DmlSubStmtList'].includes(pos.parent?.parent?.name || '') && noPrevSibling(pos);
    if (beginOfStmt) {
      keywords = keywords.concat([
        'IF',
        'WHILE',
        'FOREACH',
        'CASE',
        'TRY',
        'RAISE',
        'PRINT',
        'LOG',
        'RETURN',
        'SELECT',
        'INSERT INTO',
        'UPDATE',
        'DELETE',
        'TYPEDEF',
        'CONTINUE',
        'BREAK',
      ]);
    }

    const innerMostStmt = findInnerMostStmt(pos);
    if (innerMostStmt && innerMostStmt.name in stmtKeywordsMap) {
      keywords = keywords.concat(stmtKeywordsMap[innerMostStmt.name]);
    }

    return wrapKwOptions(pos.from, keywords);
  };
}

export function completeFromAccumTypes(): CompletionSource {
  return (context: CompletionContext) => {
    const pos: SyntaxNode | null = syntaxTree(context.state).resolveInner(context.pos, -1);
    // only when cursor is at the beginning of a statement
    if (!pos || !noPrevSibling(pos)) return null;

    let options: Completion[] = [];
    // the proper context is: 1. in the begin of a statement; 2. in the query block
    const inAccumCtx = pos.parent?.parent?.name === 'QueryBodyStmts' || findParent(pos, ['AccumType']);
    if (
      inAccumCtx ||
      (['SET', 'BAG', 'MIN', 'MAX', 'SUM', 'AVG', 'MAP', 'LIST'].includes(pos.name) &&
        pos.parent?.name !== 'ParameterType')
    ) {
      options = accums.map((accum) => ({ label: accum, type: 'type' }));
    }

    return {
      from: pos.from,
      to: undefined,
      options,
      validFor: /^\w*$/,
    };
  };
}

export function completeFromBaseTypes(): CompletionSource {
  return (context: CompletionContext) => {
    const pos: SyntaxNode | null = syntaxTree(context.state).resolveInner(context.pos, -1);
    if (!pos || pos.prevSibling?.prevSibling?.name === 'VERTEX') return null;

    let options: Completion[] = [];
    if (pos.name === 'IN' && !['ForEachStmt', 'DmlSubForEachStmt'].includes(pos.parent?.name || '')) {
      options.push({ label: 'INT', type: 'type' });
    }

    const inParamListCtx =
      (pos.parent?.parent?.name === 'CreateQuery' && pos.parent?.prevSibling?.name === '(') ||
      findParent(pos, ['ParameterList']);
    const inBaseTypeCtx =
      (['QueryBodyStmts', 'DmlSubStmtList'].includes(pos.parent?.parent?.name || '') && noPrevSibling(pos)) ||
      findParent(pos, ['BaseType', 'AccumType']);
    const inTupleTypeCtx =
      findParent(pos, ['TypedefStmt']) &&
      ['BaseType', '<'].includes(pos.prevSibling?.name || '') &&
      pos.prevSibling?.name !== '>';
    if (inBaseTypeCtx || inParamListCtx || inTupleTypeCtx) {
      options = types.map((type) => ({ label: type, type: 'type' }));
    }

    return {
      from: pos.from,
      to: undefined,
      options,
      validFor: /^\w*$/,
    };
  };
}
