import { highlightActiveLineGutter, keymap, tooltips, EditorView } from '@codemirror/view';
import { defaultKeymap, indentLess, indentMore } from '@codemirror/commands';
import ReactCodeMirror, { Statistics } from '@uiw/react-codemirror';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { EditorState } from '@codemirror/state';
import { SelectionRange, Text } from '@codemirror/state';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { useDebounceEffect } from 'ahooks';
import { TreeCursor } from '@lezer/common';
import { useEditorContext } from '@/contexts/graphEditorContext';
import useEditorTheme from '@/pages/editor/useEditorTheme';
import { gsql } from '@/pages/editor/GSQL/gsql';
import { syntaxTree } from '@codemirror/language';
import { moveCompletionSelection, completionStatus, acceptCompletion } from '@codemirror/autocomplete';

export interface CodeEditorProps {
  value: string;
  editable?: boolean;
  enableLint: boolean;
  onRunAll: () => void;
  onRunSelection: (code: string) => void;
  onCodeChange: (code: string) => void;
  onSaveFile: (code: string) => void;
}

type Position = {
  line: number;
  ch: number;
};

function posToOffset(doc: Text, pos: Position) {
  return doc.line(pos.line + 1).from + pos.ch;
}

export const GSQLEditorClassName = 'cm-gsql-container';

function printSyntaxTree(node: TreeCursor, depth: number = 0, state: EditorState) {
  const indent = '  '.repeat(depth);
  console.log(`${indent}${node.name}${node.from === node.to ? '' : `: "${state.sliceDoc(node.from, node.to)}"`}`);

  if (node.firstChild()) {
    do {
      printSyntaxTree(node, depth + 1, state);
    } while (node.nextSibling());
    node.parent();
  }
}

export function CodeEditor({
  value,
  editable = false,
  enableLint,
  onRunAll,
  onRunSelection,
  onCodeChange,
  onSaveFile,
}: CodeEditorProps) {
  const { currentWorkspace } = useWorkspaceContext();
  const { selectedCode, setSelectedCode } = useEditorContext();
  const [range, setRange] = useState<SelectionRange | null>(null); // real-time range
  const [runRange, setRunRange] = useState<SelectionRange | null>(null); // range when click the run button
  const [isRunSelection, setIsRunSelection] = useState(false);

  const [editorValue, setEditorValue] = useState(value);

  const codemirrorRef = useRef<any>();

  useDebounceEffect(
    () => {
      onCodeChange(editorValue);
    },
    [editorValue, onCodeChange],
    { wait: 200, maxWait: 1000 }
  );

  const customKeymap = useMemo(() => {
    return [
      {
        key: 'Mod-Enter',
        // prevent default behavior of codemirror
        // we implement the shortcut in Header.tsx
        run: () => true,
      },
      {
        key: 'Tab',
        shift: indentLess,
        preventDefault: true,
        run: (view: EditorView) => {
          if (completionStatus(view.state)) {
            return acceptCompletion(view);
          }
          return indentMore(view);
        },
      },
      {
        key: 'Ctrl-p',
        run: moveCompletionSelection(false),
      },
      {
        key: 'Ctrl-n',
        run: moveCompletionSelection(true),
      },
      ...defaultKeymap,
    ];
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = /Mac|iPad/i.test(navigator.platform);
      const modifierKey = isMac ? event.metaKey : event.ctrlKey;

      if (modifierKey && event.key === 's') {
        if (codemirrorRef?.current?.view) {
          const content = codemirrorRef.current.view.state.doc.toString();
          onSaveFile(content);
          event.preventDefault();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSaveFile]);

  const handleCodeChange = useCallback((value: string) => {
    setIsRunSelection(false);
    setEditorValue(value);

    // @ts-ignore
    if (codemirrorRef.current && window.printAST) {
      const tree = syntaxTree(codemirrorRef.current.view.state);
      console.log('Syntax Tree:');
      printSyntaxTree(tree.cursor(), 0, codemirrorRef.current.view.state);
    }
  }, []);

  const handleStatisticChange = useCallback(
    (value: Statistics) => {
      setRange(value.selectionAsSingle);
      setSelectedCode(value.selectionCode);
    },
    [setSelectedCode]
  );

  const extensions = useMemo(() => {
    return [
      keymap.of(customKeymap),
      highlightActiveLineGutter(),
      tooltips({
        position: 'absolute',
        tooltipSpace(view) {
          return view.dom.getBoundingClientRect();
        },
      }),
      gsql(),
    ];
  }, [customKeymap]);

  const basicSetup = useMemo(() => {
    return {
      defaultKeymap: false,
      highlightActiveLineGutter: false,
      highlightActiveLine: false,
    };
  }, []);

  const theme = useEditorTheme();

  const codeEditor = useMemo(() => {
    return (
      <ReactCodeMirror
        value={editorValue}
        className={GSQLEditorClassName}
        height={'100%'}
        theme={theme}
        onChange={handleCodeChange}
        editable={editable}
        extensions={extensions}
        basicSetup={basicSetup}
        onStatistics={handleStatisticChange}
        ref={codemirrorRef}
        indentWithTab={false}
      />
    );
  }, [editorValue, theme, handleCodeChange, editable, extensions, basicSetup, handleStatisticChange]);

  return <>{codeEditor}</>;
}
