import { getCreditCards } from '@/pages/admin/bill/api';
import { getUserCreditsRep } from '@/pages/admin/bill/Overview';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Plus } from 'baseui/icon';
import React from 'react';
import { useQuery } from 'react-query';
import NoCredit from './icons/no-credit.svg';
import NoCreditDark from './icons/no-credit-dark.svg';
import SupportIcon from './icons/support.svg?react';
import { useNavigate } from 'react-router-dom';
import { useOrgContext } from '@/contexts/orgContext';
import { getRequestMailBody } from '@/utils/utils';

export function ValidateCredit({ children }: { children: React.ReactNode }): React.ReactNode {
  const { data: userCredits } = useQuery('user-credits', getUserCreditsRep, { staleTime: 0 });
  const { data } = useQuery('credit-cards', getCreditCards);

  if (userCredits !== undefined && data !== undefined) {
    // 1. If userCredits is 0
    // 2. and We don't have a default card
    // show the CreditZero component
    if (userCredits === 0) {
      const defaultCard = data.credit_cards?.find((card) => card.card_id === data!.default_card);
      if (!defaultCard) {
        return <CreditZero />;
      }
    }
  }

  return children;
}

export default function CreditZero() {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const { userInfo, currentOrg } = useOrgContext();

  const url = `mailto:beta-support@tigergraph.com?subject=TigerGraph Cloud 4 Beta Support - ${userInfo.email} - ${
    currentOrg.org_id
  } - ${currentOrg.org_name}&body=${getRequestMailBody()}`;

  return (
    <div
      className={css({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        maxWidth: '468px',
        margin: '0 auto',
      })}
    >
      <img src={theme.name === 'dark' ? NoCreditDark : NoCredit} alt="No Credit" />
      <div
        className={css({
          textAlign: 'center',
        })}
      >
        <h1
          className={css({
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '32px',
            fontFamily: 'Urbanist',
            marginBottom: '12px',
          })}
        >
          Oops! Your Credit Balance is Exhausted!
        </h1>
        <div
          className={css({
            color: theme.colors['text.secondary'],
            marginBottom: '16px',
            lineHeight: '16px',
            fontSize: '14px',
          })}
        >
          Your available credits are exhausted. Please add a credit card to continue using our services or contact our
          support team for assistance.
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        })}
      >
        <Button
          kind="primary"
          startEnhancer={<Plus size={20} />}
          size="large"
          onClick={() => navigate(`/admin/bills?addCreditCard=true&tab=PaymentMethods`)}
        >
          Add Credit Card
        </Button>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: theme.colors['text.tertiary'],
          })}
        >
          <span
            className={css({
              height: '1px',
              flex: 1,
              backgroundColor: theme.colors.divider,
            })}
          />
          <span>or</span>
          <span
            className={css({
              height: '1px',
              flex: 1,
              backgroundColor: theme.colors.divider,
            })}
          />
        </div>
        <a
          href={url}
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            border: `1px solid ${theme.colors['button.border']}`,
            borderRadius: '2px',
            padding: '2px 12px',
            color: theme.colors['button.text'],
            fontWeight: 500,
            lineHeight: '28px',
          })}
        >
          <SupportIcon />
          Contact Support
        </a>
      </div>
    </div>
  );
}
