import { Result } from '@/lib/type';
import { createAPIKey, deleteAPIKey, listAPIKey } from '@/pages/admin/settings/apikey/api';
import { APIKey } from '@/pages/admin/settings/apikey/type';
import { modifyOrgUserRole } from '@/pages/admin/user/api';
import { AxiosError } from 'axios';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';

export function useMutationCreateAPIKey() {
  const queryClient = useQueryClient();

  return useMutation<Result<APIKey>, AxiosError, { description: string }>(
    async ({ description }) => {
      const resp = await createAPIKey(description);
      if (resp.Result) {
        const { user_id, key_name } = resp.Result;
        // assign org admin to user id
        try {
          await modifyOrgUserRole(user_id, 'super-admins');
          // debug code to check if role is assigned successfully
          // getUserDetail(user_id);
        } catch (error) {
          // if fail, delete the key
          await deleteAPIKey(key_name);
          throw error;
        }
      }

      return resp;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['api_keys']);
      },
    }
  );
}

export function useMutationDeleteAPIKey() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { key_name: string }>(
    ({ key_name }) => {
      return deleteAPIKey(key_name);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['api_keys']);
      },
    }
  );
}

export function useQueryListAPIKey(
  options?: Omit<UseQueryOptions<Result<APIKey[]>, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery(
    ['api_keys'],
    () => {
      return listAPIKey();
    },
    options
  );
}
