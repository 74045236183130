import { LoadingIndicator } from '@/components/loading-indicator';
import { useOrgContext } from '@/contexts/orgContext';
import { axiosController } from '@/lib/network';
import { cn } from '@/lib/utils';
import CurrentInvoice from '@/pages/admin/bill/CurrentInvoice';
import { getCreditCards } from '@/pages/admin/bill/api';
import { SectionContent, SectionTitle } from '@/pages/admin/bill/common';
import { calculateRoleForBilling } from '@/pages/admin/user/type';
import { CostExplorerBarGraph } from '@amberflo/uikit/components/organisms/CostExplorerBarGraph';
import { InvoiceDetails } from '@amberflo/uikit/components/organisms/InvoiceDetails';
import { UsageByMeterLineGraph } from '@amberflo/uikit/components/organisms/UsageByMeterLineGraph';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';
import { ChartOptions } from 'chart.js';
import { expand } from 'inline-style-expand-shorthand';
import { useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const CardContainer = styled('div', () => ({
  borderRadius: '4px',
  boxSizing: 'border-box',
  height: '100%',
  width: '100%',
  overflowY: 'auto',
}));

const FourDots = function () {
  const [css] = useStyletron();
  return (
    <div className={css({ flex: '1.5 1 0', letterSpacing: '6px', fontSize: '24px', textAlign: 'right' })}>
      &#x2022;&#x2022;&#x2022;&#x2022;
    </div>
  );
};

export async function getUserCreditsRep(): Promise<number> {
  const response = await axiosController.get('/v2/billing/credit');
  return response.data.Result;
}

export default function Overview() {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const [isCostLoading, setIsCostLoading] = useState(false);
  const [isInvoiceDetailLoading, setIsInvoiceDetailLoading] = useState(false);
  const [isUsageLoading, setIsUsageLoading] = useState(false);
  const { data: userCredits } = useQuery('user-credits', getUserCreditsRep, { staleTime: 0 });

  const { data, isFetching } = useQuery('credit-cards', getCreditCards);

  const defaultCard = data?.credit_cards?.find((card) => card.card_id === data!.default_card);

  const commonCardCotainerClassName = css({
    backgroundColor: theme.colors['background.primary'],
    border: `1px solid ${theme.colors['border.tertiary']}`,
  });

  const { userInfo } = useOrgContext();
  const effectRole = calculateRoleForBilling(userInfo.roles);

  const customGraphOptions: ChartOptions<any> = {
    maintainAspectRatio: false,
    plugins: {
      // 'legend' now within object 'plugins {}'
      legend: {
        labels: {
          color: theme.colors['text.primary'],
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
          drawTicks: false,
          color: theme.colors['background.accent.gray.subtlest'],
          tickColor: theme.colors['text.primary'],
          borderDash: [0, 0],
          borderColor: 'rgba(28, 28, 28, 0.40)',
          drawBorder: false,
        },
        ticks: {
          color: theme.colors['text.tertiary'],
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: theme.colors['text.primary'],
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 4,
      },
    },
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      })}
    >
      {/* line1 */}
      <div
        className={css({
          display: 'flex',
          boxSizing: 'border-box',
          fontSize: '14px',
          lineHeight: '16px',
          alignItems: 'stretch',
          minHeight: '300px',
        })}
      >
        <div
          className={css({
            width: '18.6vw',
            flex: '1 1 0',
            minWidth: '200px',
          })}
        >
          <CurrentInvoice></CurrentInvoice>
        </div>
        <div
          className={css({
            flex: '2 2 0',
            minWidth: '400px',
            margin: '0 16px',
          })}
        >
          <CardContainer style={{ padding: '16px' }} className={cn('cost-graph', commonCardCotainerClassName)}>
            {isCostLoading && <LoadingIndicator />}
            <CostExplorerBarGraph
              title="Cost"
              withContainer={false}
              customGraphOptions={customGraphOptions}
              hideLoader={true}
              onLoadingChange={(isLoading) => setIsCostLoading(isLoading)}
            ></CostExplorerBarGraph>
          </CardContainer>
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 0',
            minWidth: '350px',
          })}
        >
          {isFetching ? (
            <CardContainer
              style={{
                height: '100%',
                padding: '16px',
              }}
              className={commonCardCotainerClassName}
            >
              <LoadingIndicator />
            </CardContainer>
          ) : (
            <>
              <CardContainer
                style={{
                  height: '112px',
                  flexGrow: effectRole === 'billing-admins' && defaultCard ? 0 : 1,
                  padding: '16px',
                  marginBottom: '16px',
                }}
                className={commonCardCotainerClassName}
              >
                <SectionTitle>Credit Balance</SectionTitle>
                <SectionContent style={{ fontWeight: 600, fontSize: '24px', lineHeight: '40px' }}>
                  {`$ ${userCredits || 0}`}
                </SectionContent>
              </CardContainer>
              {defaultCard && effectRole === 'billing-admins' && (
                <CardContainer
                  style={{
                    padding: '16px',
                    flex: '1 1 0',
                    display: 'flex',
                    alignItems: 'stretch',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  className={commonCardCotainerClassName}
                >
                  <div className={css({ display: 'flex', alignItems: 'center', justifyContent: 'space-between' })}>
                    <SectionTitle>Payment methods</SectionTitle>
                    <Button
                      onClick={() => navigate(`/admin/bills?addCreditCard=true&tab=PaymentMethods`)}
                      overrides={{
                        BaseButton: {
                          style: {
                            fontSize: '14px',
                            lineHeight: '16px',
                            fontWeight: 500,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '34px',
                            boxSizing: 'border-box',
                            letterSpacing: 'normal',
                            ...expand({ padding: '8px' }),
                          },
                        },
                      }}
                    >
                      <FiPlusCircle />
                      <span className={css({ paddingLeft: '8px' })}>Add Payment Method</span>
                    </Button>
                  </div>
                  <div className={css({ marginTop: '16px', display: 'flex', alignItems: 'center' })}>
                    <img
                      width="48px"
                      height="32px"
                      src={`/credit-cards/${defaultCard.brand}.svg`}
                      alt={defaultCard.brand}
                    />
                    <FourDots />
                    <FourDots />
                    <FourDots />
                    <div className={css({ flex: '1 1 0', textAlign: 'right', ...theme.typography.Body1 })}>
                      {defaultCard.last_4_digits}
                    </div>
                  </div>
                  <div
                    className={css({
                      textAlign: 'right',
                      color: '#1976D2',
                      fontWeight: 600,
                      marginTop: '20px',
                      ':hover': {
                        cursor: 'pointer',
                      },
                    })}
                    onClick={() => navigate('/admin/bills?tab=PaymentMethods')}
                  >
                    More
                  </div>
                </CardContainer>
              )}
            </>
          )}
        </div>
      </div>

      {/* line2 */}
      <div
        className={css({
          boxSizing: 'border-box',
          display: 'flex',
          marginTop: '16px',
          height: '400px',
          minWidth: 0,
          flex: '1 1 0',
        })}
      >
        <CardContainer
          style={{
            marginRight: '16px',
            padding: '16px',
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 1,
            minWidth: '400px',
            overflowY: 'hidden',
          }}
          className={commonCardCotainerClassName}
        >
          {isInvoiceDetailLoading && <LoadingIndicator />}
          <div className="invoice-details">
            <InvoiceDetails
              withInvoiceTotal={false}
              withContainer={false}
              hideLoader={true}
              onLoadingChange={(isLoading) => setIsInvoiceDetailLoading(isLoading)}
            ></InvoiceDetails>
          </div>
        </CardContainer>
        <CardContainer
          style={{ padding: '16px', flexBasis: 0, flexGrow: 2, flexShrink: 1, minWidth: '600px' }}
          className={cn('usage-graph', commonCardCotainerClassName)}
        >
          {isUsageLoading && <LoadingIndicator />}
          <UsageByMeterLineGraph
            withDimensionsSelector={true}
            withMeterSelector={true}
            withTitle={true}
            title="Usage"
            withContainer={false}
            customGraphOptions={customGraphOptions}
            hideLoader={true}
            onLoadingChange={(isLoading) => setIsUsageLoading(isLoading)}
          ></UsageByMeterLineGraph>
        </CardContainer>
      </div>
    </div>
  );
}
