import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { APIKey } from '@/pages/admin/settings/apikey/type';

export async function createAPIKey(description: string): Promise<Result<APIKey>> {
  const response = await axiosController.post(`v2/org/api-keys`, { description });
  return response.data;
}

export async function deleteAPIKey(key_name: string): Promise<Result<void>> {
  const response = await axiosController.delete(`v2/org/api-keys`, {
    data: {
      key_name,
    },
  });
  return response.data;
}

export async function listAPIKey(): Promise<Result<APIKey[]>> {
  const response = await axiosController.get(`v2/org/api-keys`);
  return response.data;
}
