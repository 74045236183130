import { useLatestInvoice } from '@amberflo/uikit/hooks';
import { dateUtils } from '@amberflo/uikit/utils';
import { LoadingIndicator } from '@/components/loading-indicator';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { SectionContent, SectionTitle } from '@/pages/admin/bill/common';
import { Invoice } from '@amberflo/uikit/types';

// type CurrentInvoiceProps = {};

const Container = styled('div', () => ({
  height: '100%',
  borderRadius: '4px',
  padding: '24px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

function calculateForcastedUsage(currentUsage: number, invoice: Invoice) {
  const { invoiceStartTimeInSeconds, invoiceEndTimeInSeconds } = invoice;
  const now = Date.now() / 1000;
  const averageSecondUsage = currentUsage / (now - invoiceStartTimeInSeconds);
  return ((invoiceEndTimeInSeconds - invoiceStartTimeInSeconds) * averageSecondUsage).toFixed(2);
}

function CurrentInvoice() {
  const [css, theme] = useStyletron();
  const { data, isFetching } = useLatestInvoice();

  const billingStartDate = dateUtils.getDateFromTimestamp(data?.invoiceStartTimeInSeconds, false, 'mm/dd/yyyy');
  const billingEndDate = dateUtils.getDateFromTimestamp(data?.invoiceEndTimeInSeconds, false, 'mm/dd/yyyy');
  const totalPrice = data?.invoiceBillInBaseCurrency.totalPrice.toFixed(2);
  const forcastedPrice = data ? calculateForcastedUsage(data?.invoiceBillInBaseCurrency.totalPrice, data) : 0;
  const commonContainerClassname = css({
    border: `1px solid ${theme.colors['border.tertiary']}`,
    color: theme.colors['text.primary'],
    background: theme.colors['background.primary'],
    overflow: 'auto',
  });

  return isFetching ? (
    <Container className={commonContainerClassname}>
      <LoadingIndicator />
    </Container>
  ) : data ? (
    <Container className={commonContainerClassname}>
      <SectionTitle>Billing Period</SectionTitle>
      <SectionContent>
        {billingStartDate}-{billingEndDate}
      </SectionContent>
      <SectionTitle>Current Month Usage</SectionTitle>
      <SectionContent>{`$ ${totalPrice}`}</SectionContent>
      <SectionTitle>Estimated end-of-Month Cost (forecasted)</SectionTitle>
      <SectionContent>{`$ ${forcastedPrice}`}</SectionContent>
    </Container>
  ) : null;
}

export default CurrentInvoice;
