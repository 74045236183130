import { SubChapterType, Tutorial } from '@/components/tutorial/type.ts';
import { DataIngestion, ExploreGraph, GSQLEditor, Workgroups } from '@/components/tutorial/icons.tsx';
import { SpaceIcon } from '@/pages/home/icons.tsx';

// mock Tutorials
export const Tutorials: Tutorial[] = [
  {
    id: '0',
    title: 'Getting Started',
    icon: <SpaceIcon />,
    routerKey: 'workspace',
    abstract: '',
    sub_chapter: [],
  },
  {
    id: '1',
    title: 'Workgroup & Workspace',
    icon: <Workgroups />,
    routerKey: 'groups',
    abstract:
      'The tutorials for workgroup and workspace in TigerGraph Cloud guide users in setting up, configuring, and managing workspaces, including creating new workspaces, setting up auto start/stop, adjusting resources, performing backups and restores, and monitoring workspace health and performance. These tutorials provide a comprehensive understanding of workspace management in TigerGraph Cloud.',
    sub_chapter: [
      {
        Title: 'Create Workspace',
        Type: SubChapterType.Video,
        Description:
          'This tutorial provides step-by-step instructions on creating a new workspace in TigerGraph Cloud, allowing users to set up a workspace with a suitable name and description, and configure workspace settings based on their specific requirements.',
        Url: 'https://drive.google.com/file/d/1woaoDOqWbgjrp0ZZktBfA4vAqg6UR9Ly/preview',
        TutorialID: '11',
      },
      {
        Title: 'Create Read-Only Workspace',
        Type: SubChapterType.Video,
        Description:
          'The tutorial provides step-by-step instructions on how to create a read-only workspace in TigerGraph Cloud. It covers accessing the workspace management section, selecting the read-only workspace type, configuring the workspace size and policies, waiting for the creation process to complete, and updating the read-only data from the read-write workspace.',
        Url: 'https://drive.google.com/file/d/1ePkncg_Ao7MaMm6TUOoq3K_NcwGDLmTo/preview',
        TutorialID: '12',
      },
      {
        Title: 'Monitor Workspace',
        Type: SubChapterType.Video,
        Description:
          'The Monitor feature in TigerGraph Cloud allows you to track the system health, network stats, and query performance of your workspaces, providing valuable insights into resource usage, query efficiency, and component health to ensure optimal performance and identify any issues. By monitoring these metrics, you can make informed decisions, troubleshoot problems, and maintain the overall health of your TigerGraph workspaces.',
        Url: 'https://drive.google.com/file/d/1YMSVFrmCay6xxzgXHEqVYojxgh4WUsrz/preview',
        TutorialID: '13',
      },
      // {
      //   Title: 'Backup & Restore',
      //   Description: 'Coming soon...',
      //   VideoUrl: 'https://www.youtube.com/embed/_LkDBMrx_5I?si=gx0rvtWizP9LPqcN',
      //   TutorialID: '14',
      // },
      {
        Title: 'Get Data Profile',
        Type: SubChapterType.Video,
        Description:
          'The "Get Data Profile" tutorial is a comprehensive, step-by-step guide that demonstrates how to leverage built-in graph queries to extract valuable insights from your data. By following this tutorial, you will gain a deeper understanding of the patterns, trends, and relationships within your data.',
        Url: 'https://drive.google.com/file/d/1hJ-zgXkAF4WRX_3TjZH3hTRwYyYYGo2z/preview',
        TutorialID: '15',
      },
      {
        Title: 'Connect From API',
        Type: SubChapterType.Video,
        Description:
          'This tutorial demonstrates how to establish a connection to TigerGraph Cloud using various programming languages like Python and JavaScript, enabling users to interact with their graph databases programmatically and perform operations such as data insertion, retrieval, and modification.',
        Url: 'https://drive.google.com/file/d/1HXj1lJ-bHICIu6chFRNvePrpxiU7bEYL/preview',
        TutorialID: '16',
      },
    ],
  },
  {
    id: '2',
    title: 'Load Data',
    icon: <DataIngestion />,
    routerKey: 'ingestion',
    abstract:
      'Please follow this tutorial to learn how to load your data into TigerGraph. We provide support for various formats, including JSON and CSV, and you can load data from your local file, AWS S3, GCP Cloud Storage, or Azure Blob Storage.',
    sub_chapter: [
      {
        Title: 'Load Local CSV File',
        Type: SubChapterType.Video,
        Description:
          'The tutorial on loading local CSV files in TigerGraph Cloud provides step-by-step instructions on how to upload CSV files, review and adjust configurations, and utilize automatic schema and mapping generation. It emphasizes the importance of carefully reviewing mappings, monitoring the loading status, and the ability to make edits and reconfigure mappings if needed during the process.',
        Url: 'https://drive.google.com/file/d/1bIrVb2iucdwVgrZAk2eIoNOSKe1IKFQV/preview',
        TutorialID: '21',
      },
      {
        Title: 'Load From Amazon S3',
        Type: SubChapterType.Video,
        Description:
          'The tutorial provide step-by-step instructions on how to import data stored in Amazon S3 into TigerGraph Cloud, allowing you to integrate and leverage your S3 data within the TigerGraph graph analytics platform.',
        Url: 'https://drive.google.com/file/d/10NcgppBzv7u9qgnCtsii6K69tlT3HGLz/preview',
        TutorialID: '22',
      },
    ],
  },
  {
    id: '3',
    title: 'GSQL Editor',
    routerKey: 'editor',
    icon: <GSQLEditor />,
    abstract:
      'The GSQL Editor is a web-based interface provided by TigerGraph that allows users to write, execute, and debug GSQL queries. It provides a convenient environment for developing and testing GSQL code, which is a graph query language specifically designed for TigerGraph databases. The GSQL Editor offers features such as syntax highlighting, code suggestions, and query execution history to streamline the query development process.',
    sub_chapter: [
      {
        Title: 'Develop Using GSQL Editor',
        Type: SubChapterType.Video,
        Description:
          'The GSQL Editor is a powerful tool that allows you to write and execute GSQL queries for graph analysis in TigerGraph. It provides a user-friendly interface with various features to enhance your query development experience.',
        Url: 'https://drive.google.com/file/d/1bf7i2l4OgTm8FISwxr8yPDtYgGaeHOdZ/preview',
        TutorialID: '31',
      },
      {
        Title: 'GSQL 101',
        Type: SubChapterType.Link,
        Description:
          'In this tutorial, we will introduce core concepts in creating and querying graph databases. We will show you how to create a graph schema, load data, write simple parameterized queries, and run your queries.',
        Url: 'https://docs.tigergraph.com/gsql-ref/current/tutorials/gsql-101/',
        TutorialID: '31',
      },
    ],
  },
  {
    id: '4',
    title: 'Explore Graph',
    routerKey: 'explore',
    icon: <ExploreGraph />,
    abstract:
      'The Graph Explorer is a web-based tool provided by TigerGraph Cloud that allows users to visually explore and analyze graph data stored in TigerGraph databases. It provides an intuitive interface for querying and navigating the graph, enabling users to traverse nodes and edges, apply filters and aggregations, and visualize the results in various formats.',
    sub_chapter: [
      {
        Title: 'Explore Graph Using Pattern Search',
        Type: SubChapterType.Video,
        Description:
          'The Explore Graph tutorials provide concise and informative guidance on leveraging the graph exploration capabilities, helping users understand and utilize the various features and functionalities of the Explore Graph tool effectively.',
        Url: 'https://drive.google.com/file/d/1A42xQdpoNb1rP6Ydt2konWo4iWkn_JTB/preview',
        TutorialID: '41',
      },
    ],
  },
  // {
  //   id: '5',
  //   title: 'Marketplace',
  //   routerKey: 'marketplace',
  //   icon: <Integration />,
  //   abstract:
  //     'Please follow this tutorial to learn how to load your data into TigerGraph. We provide support for various formats, including JSON and CSV, and you can load data from your local file, AWS S3, GCP Cloud Storage, or Azure Blob Storage.',
  //   sub_chapter: [
  //     {
  //       Title: 'Add-on',
  //       Description: 'Coming soon...',
  //       VideoUrl: 'https://www.youtube.com/embed/_LkDBMrx_5I?si=gx0rvtWizP9LPqcN',
  //       TutorialID: '51',
  //     },
  //     {
  //       Title: 'Install Solution',
  //       Description: 'Coming soon...',
  //       VideoUrl: 'https://www.youtube.com/embed/_LkDBMrx_5I?si=gx0rvtWizP9LPqcN',
  //       TutorialID: '52',
  //     },
  //   ],
  // },
  // {
  //   id: '6',
  //   title: ' Admin',
  //   routerKey: 'admin',
  //   icon: <AdminIcon />,
  //   abstract:
  //     'Please follow this tutorial to learn how to load your data into TigerGraph. We provide support for various formats, including JSON and CSV, and you can load data from your local file, AWS S3, GCP Cloud Storage, or Azure Blob Storage.',
  //   sub_chapter: [
  //     {
  //       Title: 'Invite User',
  //       Description: 'Coming soon...',
  //       VideoUrl: 'https://www.youtube.com/embed/_LkDBMrx_5I?si=gx0rvtWizP9LPqcN',
  //       TutorialID: '61',
  //     },
  //     {
  //       Title: 'Access Management',
  //       Description: 'Coming soon...',
  //       VideoUrl: 'https://www.youtube.com/embed/_LkDBMrx_5I?si=gx0rvtWizP9LPqcN',
  //       TutorialID: '62',
  //     },
  //     {
  //       Title: 'Organization Setting',
  //       Description: 'Coming soon...',
  //       VideoUrl: 'https://www.youtube.com/embed/_LkDBMrx_5I?si=gx0rvtWizP9LPqcN',
  //       TutorialID: '63',
  //     },
  //     {
  //       Title: 'Billing',
  //       Description: 'Coming soon...',
  //       VideoUrl: 'https://www.youtube.com/embed/_LkDBMrx_5I?si=gx0rvtWizP9LPqcN',
  //       TutorialID: '64',
  //     },
  //   ],
  // },
];
